import React, {FC, useEffect, useState} from "react";
import './InvoiceSectionPage.scss';
import {Field, Form, Formik} from "formik";
import {Accordion, Container} from "react-bootstrap";
import usePagination from "../../helpers/UsePagination";
import {EditInvoice, Invoice} from "../../models/Invoice";
import {invoicesPaginateRequest, updateInvoice} from "../../@api/Invoice";
import AppModal from "../../ui/Modal/AppModal/AppModal";
import AmountForm from "../../components/InvoiceSectionPage/AmountForm";
import FormikDateInput from "../../components/Inputs/FormikDateInput/FormikDateInput";
import {dateToLocaleString} from "../../helpers/DateFormatter";
import AppTable from "../../ui/Table/AppTable/AppTable";
import {handleOnDatesChange, handleOnFiltersChange, handleReset} from "../../helpers/Filters";
import {RootState} from "../../store/Store";
import {useSelector} from "react-redux";
import {PaymentMethods} from "../../enums/PaymentMethods";
import EditInvoiceForm from "./EditInvoiceForm/EditInvoiceForm";
import {getCurrencySymbol} from "../../helpers/CurrencyConverter";
import {HttpSuccessResponse} from "../../@api/Responses/HttpSuccessResponse";
import {useNavigate} from "react-router-dom";
import AppButton from "../../ui/Buttons/AppButton/AppButton";
import AppLoader from "../../ui/Loader/AppLoader/AppLoader";

interface FilterProps {
    date_from: string;
    date_to: string;
    sort_by: string;
}

const InvoiceSectionPage: FC = () => {
    const tableHeaders = [
        'ID',
        'PO ID',
        'Invoice Date',
        'Invoice Total',
        'Quantity',
        'Customer email',
        'Customer name',
        'External Ref.',
        'Event Name',
        'Event Date',
        'Payment Method',
        'Fulfillment Status',
        'Received',
        'Public notes',
        'Created By',
        'Actions'
    ];

    const currencies = useSelector((state: RootState) => state.currency.currencies);

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const filterInitial: FilterProps = {
        date_from: '',
        date_to: '',
        sort_by: 'ASC'
    };

    const dateFilters = [
        {name: 'date_from', placeholder: 'Date From'},
        {name: 'date_to', placeholder: 'Date To'},
    ];

    const [showCost, setShowCost] = useState<number | null>(null);
    const [selectedInvoiceFormId, setSelectedInvoiceFormId] = useState<number | undefined>(undefined);
    const [filterValues, setFilterValues] = useState<FilterProps>(filterInitial);
    const [invoices, setInvoices] = useState<Invoice[]>([]);

    const pagination = usePagination<Invoice>(invoicesPaginateRequest);

    useEffect(() => {
        pagination.paginate(filterInitial);
    }, []);

    useEffect(() => {
        setInvoices(pagination.items)
    }, [pagination.items]);

    const handleCostClose = () => setShowCost(null);

    const handleCloseInvoiceForm = () => setSelectedInvoiceFormId(undefined);

    const handleSubmitEditInvoice = async (values: EditInvoice, id: number) => {
        setIsLoading(true);

        const res = await updateInvoice(values, id);

        if (res instanceof HttpSuccessResponse) {
            handleCloseInvoiceForm();
            setIsLoading(false);
            pagination.paginate(filterInitial);
        }

        setIsLoading(false);
    };

    const handleCreateInvoiceClick = (invoice: Invoice) => {
        navigate(`/user/invoices/${invoice.id}`, {
            state: {inventoryData: invoice.inventory, customer: invoice.customers}
        });
    };

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>Invoices</h1>
            {!pagination.isLoading ?
                <div className="mb-3">
                    <Accordion>
                        <Accordion.Item eventKey={'0'}>
                            <Accordion.Header>
                                Filters
                            </Accordion.Header>
                            <Accordion.Body>
                                <Formik
                                    initialValues={filterInitial}
                                    onSubmit={async (values) => {
                                        pagination.setPerPage(pagination.perPage);
                                        await pagination.paginate(values);
                                    }}
                                >
                                    {({resetForm}) => {
                                        return (
                                            <Form
                                                className="table__filters"
                                                onChange={(event) => handleOnFiltersChange(event, filterValues, setFilterValues)}>
                                                <div className="table__filters__wrapper">
                                                    <Field name={'sort_by'} as="select">
                                                        <option value="ASC">Sort by date ↓</option>
                                                        <option value="DESC">Sort by date ↑</option>
                                                    </Field>
                                                    {dateFilters.map((data, index) => (
                                                        <FormikDateInput
                                                            key={index + 'Dates'}
                                                            name={data.name}
                                                            placeholderText={data.placeholder}
                                                            showTimeSelect
                                                            autoComplete={'off'}
                                                            sendDate={(date: string, name: string) =>
                                                                handleOnDatesChange(date, name as keyof FilterProps, filterValues, setFilterValues)
                                                            }
                                                        />
                                                    ))}
                                                    <button type="reset"
                                                            className="filters-button-reset"
                                                            onClick={async () => {
                                                                await handleReset(resetForm, filterInitial, pagination, setFilterValues);
                                                            }}
                                                    >
                                                        Reset
                                                    </button>
                                                    <button type="submit" className="filters-button-apply">
                                                        Apply Filters
                                                    </button>
                                                </div>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                :
                null
            }
            {
                !pagination.isLoading ?
                    <AppTable
                        columns={tableHeaders}
                        pagination={{
                            lastPage: pagination.lastPage,
                            currentPage: pagination.currentPage,
                            total_items: pagination.totalItems
                        }}
                        onPageChange={(page, perPage) => {
                            pagination.setCurrentPage(page);
                            pagination.setPerPage(perPage);
                            pagination.paginate(filterValues);
                        }}
                    >
                        <>
                            {invoices.length ?
                                invoices.map((invoice, index) => (
                                    <React.Fragment key={index}>
                                        <tr className={"invoice-section-page__tr"} data-row={index}>
                                            <td>{invoice.id}</td>
                                            <td>{invoice.sb_invoice?.purchase_id || invoice.purchase_order_id || ''}</td>
                                            <td>{dateToLocaleString(invoice.date)}</td>
                                            <td>{getCurrencySymbol(invoice.currency_code, currencies)}{(Number(invoice.quantity ?? 1) * invoice.amount).toFixed(2)}</td>
                                            <td>{invoice.quantity}</td>
                                            <td>{invoice.customer_email ? invoice.customer_email : invoice.customers?.email}</td>
                                            <td>{invoice.customer_name ? invoice.customer_name : invoice.customers?.display_name}</td>
                                            <td>{invoice.external_reference || invoice.sb_invoice?.external_reference}</td>
                                            <td>{invoice.event_name}</td>
                                            <td>{dateToLocaleString(invoice.event_date)}</td>
                                            <td>
                                                {invoice.payment_method
                                                    ? PaymentMethods[invoice.payment_method as keyof typeof PaymentMethods]
                                                    : invoice.customers
                                                        ? PaymentMethods[invoice.customers.payment_method as keyof typeof PaymentMethods]
                                                        : invoice.sb_invoice?.payment_method
                                                }
                                            </td>
                                            <td>{invoice.fulfillment_status || invoice.sb_invoice?.fulfillment_status || 'Pending'}</td>
                                            <td>{'No' || invoice.sb_invoice?.received}</td>
                                            <td>{invoice.public_notes}</td>
                                            <td>{invoice.owner?.name || ''}</td>
                                            <td>
                                                {invoice.inventory && (
                                                    <div className="user-sell-page__dropdown-item"
                                                         onClick={() => handleCreateInvoiceClick(invoice)}>
                                                        <AppButton text={'Edit'} color={'red'}/>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                        <AppModal
                                            key={'costModal' + invoice.id}
                                            show={showCost === invoice.id}
                                            showHandle={handleCostClose}
                                            title={'Set custom amount'}
                                            form={<AmountForm showHandle={handleCostClose} invoice={invoice}/>}
                                            onClose={() => {
                                            }}
                                        />
                                        <AppModal
                                            customClass={'invoice-modal'}
                                            title={'Edit Invoice'}
                                            centered={true}
                                            form={
                                                <EditInvoiceForm
                                                    isLoading={isLoading}
                                                    invoice={invoice}
                                                    onSubmit={(values) => handleSubmitEditInvoice(values, invoice.id)}
                                                />
                                            }
                                            show={selectedInvoiceFormId === invoice.id}
                                            showHandle={handleCloseInvoiceForm}
                                            onClose={() => {
                                            }}
                                        />
                                    </React.Fragment>
                                )) :
                                <tr>
                                    <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>No
                                        results
                                    </td>
                                </tr>
                            }
                        </>
                    </AppTable>
                    :
                    <AppLoader/>
            }
        </Container>
    );
}
export default InvoiceSectionPage;
