import React, {FC, Fragment, useEffect, useState} from "react";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import usePagination from "../../../helpers/UsePagination";
import {SbEvent} from "../../../models/Event";
import {sbEventsPaginateRequest} from "../../../@api/Event";
import {Accordion, Container} from "react-bootstrap";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import {StateTypes} from "../../../enums/Skybox/StateTypes";
import {EventTypes} from "../../../enums/Skybox/EventTypes";
import './UserEventsPage.scss';
import DefaultSpinner from "../../../ui/Spinners/DefaultSpinner/DefaultSpinner";
import {Field, Form, Formik} from "formik";
import {handleOnDatesChange, handleOnFiltersChange, handleReset} from "../../../helpers/Filters";
import FormikDateInput from "../../../components/Inputs/FormikDateInput/FormikDateInput";
import AppSmartSearch from "../../../ui/Inputs/AppSmartSearch/AppSmartSearch";
import {performersGetCategoriesRequest, performersNotPaginated} from "../../../@api/Performer";
import DefaultFormikInput from "../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import {PerformerCategoriesResponse} from "../../../@api/Responses/PerformerResponses";
import AppButton from "../../../ui/Buttons/AppButton/AppButton";
import AppModal from "../../../ui/Modal/AppModal/AppModal";
import InventoryInfoModal from "./InventoryInfoModal/InventoryInfoModal";
import {Link} from "react-router-dom";

interface FilterProps {
    from_date: Date | string | undefined;
    to_date: Date | string | undefined;
    sort_by: string;
    id: string,
    name: string,
    venue: string,
    city: string,
    performer: string,
    category: string,
    category_type: string,
    with_tickets_numbers: boolean
}

const UserEventsPage: FC = () => {
    const tableHeaders = [
        'Event ID',
        'Event Name',
        'Date',
        'Day of week',
        'Event Type',
        'Category',
        'Venue',
        'Performer',
        'City',
        'State',
        'Event Tags',
        'My # Of Tickets',
        'My Sold Tickets',
        'My Cancelled Tickets',
        ''
    ];

    const eventFormValues = [
        [
            {key: "id", value: "Search by Event ID"},
            {key: "name", value: "Search events by name"},
            {key: "venue", value: "Search events by venue"},
            {key: "city", value: "Search events by city"}
        ],
    ];

    const filterInitial: FilterProps = {
        from_date: new Date(),
        to_date: undefined,
        sort_by: 'ASC',
        id: '',
        name: '',
        venue: '',
        city: '',
        performer: '',
        category: '',
        category_type: '',
        with_tickets_numbers: true
    };

    const dateFilters = [
        {name: 'from_date', placeholder: 'Date From'},
        {name: 'to_date', placeholder: 'Date To'},
    ];

    const [filterValues, setFilterValues] = useState<FilterProps>(filterInitial);
    const [categories, setCategories] = useState<PerformerCategoriesResponse>();
    const [eventTypes, setEventTypes] = useState<string[]>([]);
    const [showSold, setShowSold] = useState<number | null>(null)
    const [soldMode, setSoldMode] = useState<boolean>(true)

    const pagination = usePagination<SbEvent>(sbEventsPaginateRequest);

    useEffect(() => {
        performersGetCategoriesRequest()
            .then((res) => {
                if (res instanceof HttpSuccessResponse) {
                    setCategories(res.data);
                    setEventTypes(
                        Object.keys(res.data)
                            .flat()
                            .filter((category) => category)
                            .map((category) => category)
                    );
                }
            });

        pagination.paginate(filterInitial);
    }, []);

    const handleCategoryTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const categoryNameSelect = document.getElementsByName('category')[0] as HTMLSelectElement;

        while (categoryNameSelect.options.length > 0) {
            categoryNameSelect.remove(0);
        }

        if (categories) {
            categories[e.target.value as keyof typeof categories].forEach((item) => {
                categoryNameSelect.add(new Option(String(item.category_name), String(item.category_name)));
            });
        }
    }

    const handleSoldModal = () => {
        setShowSold(null);
        setSoldMode(true)
    };

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>Events</h1>
            <div className="mb-3">
                <Accordion>
                    <Accordion.Item eventKey={'0'}>
                        <Accordion.Header>
                            Filters
                        </Accordion.Header>
                        <Accordion.Body>
                            <Formik
                                initialValues={filterInitial}
                                onSubmit={async (values) => {
                                    pagination.setPerPage(pagination.perPage);
                                    await pagination.paginate(values);
                                }}
                            >
                                {({resetForm}) => {
                                    return (
                                        <Form className="table__filters"
                                              onChange={(event) => handleOnFiltersChange(event, filterValues, setFilterValues)}>
                                            <div className="table__filters__wrapper">
                                                <Field name={'sort_by'} as="select">
                                                    <option value="ASC">Sort by date ↓</option>
                                                    <option value="DESC">Sort by date ↑</option>
                                                </Field>
                                                {dateFilters.map((data, index) => (
                                                    <FormikDateInput
                                                        key={index + 'Dates'}
                                                        name={data.name}
                                                        placeholderText={data.placeholder}
                                                        showTimeSelect
                                                        autoComplete={'off'}
                                                        sendDate={(date: string, name: string) =>
                                                            handleOnDatesChange(date, name as keyof FilterProps, filterValues, setFilterValues)
                                                        }
                                                    />
                                                ))}
                                                <div className="events-page__filters__component">
                                                    <AppSmartSearch
                                                        name={'performer'}
                                                        placeholder={'Search by performer'}
                                                        requestFunction={performersNotPaginated}
                                                        valuesKey={'name'}
                                                    />
                                                </div>

                                            </div>
                                            {eventFormValues.map(formValue => (
                                                <div key={formValue.map(formField => formField.key).join('')}
                                                     className="table__filters__wrapper">
                                                    {formValue.map(formField => (
                                                        <DefaultFormikInput
                                                            key={formField.key}
                                                            name={formField.key}
                                                            placeholder={formField.value}
                                                            autocomplete={'off'}
                                                            class={'filters-search'}
                                                        ></DefaultFormikInput>
                                                    ))}
                                                </div>
                                            ))}

                                            <div>
                                                <div className="table__filters__wrapper">
                                                    <Field name={'category_type'} as="select"
                                                           onInput={handleCategoryTypeChange}>
                                                        <option key={'eventType'} value="" disabled>Search by event
                                                            type
                                                        </option>
                                                        {
                                                            eventTypes.map(eventType => (
                                                                <option key={eventType + 'EventType'} value={eventType}>
                                                                    {EventTypes[eventType as keyof typeof EventTypes]}
                                                                </option>
                                                            ))
                                                        }
                                                    </Field>
                                                    <Field name={'category'} as="select">
                                                        <option disabled value="">Select category name</option>
                                                    </Field>
                                                    <button type="reset"
                                                            className="filters-button-reset"
                                                            onClick={async () => {
                                                                await handleReset(resetForm, filterInitial, pagination, setFilterValues);
                                                            }}
                                                    >
                                                        Reset
                                                    </button>
                                                    <button type="submit" className="filters-button-apply">
                                                        Apply Filters
                                                    </button>

                                                </div>
                                            </div>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <>
                <AppTable
                    columns={tableHeaders}
                    pagination={{
                        lastPage: pagination.lastPage,
                        currentPage: pagination.currentPage,
                        total_items: pagination.totalItems
                    }}
                    onPageChange={(page, perPage) => {
                        pagination.setCurrentPage(page);
                        pagination.setPerPage(perPage);
                        pagination.paginate(filterValues);
                    }}>
                    {pagination.isLoading
                        ?
                        <tr>
                            <td className={'no-border-td'} colSpan={1000}>
                                <DefaultSpinner/>
                            </td>
                        </tr>
                        :
                        <> {pagination.items.length ? (
                                pagination.items.map((event, index) =>
                                    <Fragment key={index}>
                                        <tr data-row={index} key={event.id + index}>
                                            <td>{event.event_id}</td>
                                            <td className={'event-name-td'} title={event.name}>{event.name}</td>
                                            <td>{dateToLocaleString(event.occurs_at)}</td>
                                            <td>{new Date(event.occurs_at).toLocaleString('en-us', {weekday: 'long'})}</td>
                                            <td>{EventTypes[event.sb_performer?.category_type as keyof typeof EventTypes]}</td>
                                            <td>{event.sb_performer?.category_name}</td>
                                            <td className={'event-name-td'} title={event.venue}>{event.venue}</td>
                                            <td>{event.sb_performer?.name}</td>
                                            <td>{event.city}</td>
                                            <td>{StateTypes[event.state_code as keyof typeof StateTypes] || '-'}</td>
                                            <td>{event.data?.tags || '-'}</td>
                                            <td>{event.count_own_inventories}</td>
                                            <td>{event.count_sold_own_inventories}</td>
                                            <td>-</td>
                                            <td>
                                                <div className={'customers_actions'}>
                                                    <div onClick={() => {
                                                        setShowSold(event.event_id)
                                                    }}>
                                                        <AppButton text={'View sold'}/>
                                                    </div>
                                                    <div onClick={() => {
                                                        setShowSold(event.event_id)
                                                        setSoldMode(false)
                                                    }}>
                                                        <AppButton text={'View purchased'}/>
                                                    </div>
                                                    <div>
                                                        <Link to={`/user/sell/sell-ticket?eventId=${event.event_id}`}>
                                                            <AppButton text={'Add inventory'}></AppButton>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <AppModal
                                            customClass={'sold-inventory-modal'}
                                            centered={true}
                                            title={soldMode ? 'Sold inventories' : 'Purchased inventories'}
                                            form={
                                                <InventoryInfoModal
                                                    event={event}
                                                    soldViewMode={soldMode}
                                                />
                                            }
                                            show={showSold === event.event_id}
                                            showHandle={handleSoldModal}
                                            onClose={() => {
                                            }}
                                        />
                                    </Fragment>
                                )
                            )
                            :
                            null
                        }
                        </>
                    }
                </AppTable>
            </>

        </Container>
    )
}

export default UserEventsPage
