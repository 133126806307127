import React, {FC, useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {Form, Formik} from "formik";
import usePagination from '../../../helpers/UsePagination';
import {Performer, PerformerFormValidationSchema} from "../../../models/Performer";
import {performersPaginateRequest, performersSetPercentRequest} from "../../../@api/Performer";
import DefaultFormikInput from "../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import {HttpFailureResponse} from "../../../@api/Responses/HttpFailureResponse";
import {handleOnFiltersChange, handleReset} from "../../../helpers/Filters";

interface FilterProps {
    name: string;
}

const PerformersPage: FC = () => {
    const tableHeaders = [
        'Name',
        'Percent',
    ];

    const filterInitial: FilterProps = {
        name: '',
    };

    const [filterValues, setFilterValues] = useState<FilterProps>(filterInitial);

    const pagination = usePagination<Performer>(performersPaginateRequest);

    useEffect(() => {
        pagination.paginate(filterInitial);
    }, []);

    const handleOnPercentSet = (index: number, performer: Performer, value: { percent: number }) => {
        const button = document.querySelector('[data-row=\'' + index + '\']')
            ?.querySelector('.admin-users-page__percent-td')
            ?.querySelector('.admin-users-page__percent-form')?.querySelector('button');
        let tr = button?.querySelector('.admin-users-page__percent-invisible');

        if (tr != null) {
            tr.classList.remove('admin-users-page__percent-invisible');
            tr.classList.add('admin-users-page__percent-visible');
        }

        const data: Performer = {
            id: Number(performer.id),
            name: performer.name,
            percent: Number(value.percent),
        };

        performersSetPercentRequest(data)
            .then((res) => {
                tr = button?.querySelector('.admin-users-page__percent-visible');

                if (tr != null) {
                    tr.classList.remove('admin-users-page__percent-visible');
                    tr.classList.add('admin-users-page__percent-invisible');
                }

                if (res instanceof HttpFailureResponse) {
                    alert(res.message);
                }
            })
    };

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>Performers</h1>

            <div className="mb-3">
                <Formik
                    initialValues={filterInitial}
                    onSubmit={async (values) => {
                        pagination.setPerPage(pagination.perPage);
                        await pagination.paginate(values);
                    }}
                >
                    {({resetForm}) => {
                        return (
                            <Form className="table__filters"
                                  onChange={(event) => handleOnFiltersChange(event, filterValues, setFilterValues)}>
                                <div className="table__filters__wrapper">
                                    <DefaultFormikInput
                                        name={'name'}
                                        placeholder={'Search performers by name'}
                                        autocomplete={'off'}
                                        class={'mb-2'}
                                    ></DefaultFormikInput>
                                    <button type="reset"
                                            className="filters-button-reset"
                                            onClick={async () => {
                                                await handleReset(resetForm, filterInitial, pagination, setFilterValues);
                                            }}
                                    >
                                        Reset
                                    </button>
                                    <button type="submit" className="filters-button-apply">
                                        Apply Filters
                                    </button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>

            <AppTable
                columns={tableHeaders}
                pagination={{
                    lastPage: pagination.lastPage,
                    currentPage: pagination.currentPage,
                    total_items: pagination.totalItems
                }}
                onPageChange={(page, perPage) => {
                    pagination.setCurrentPage(page);
                    pagination.setPerPage(perPage);
                    pagination.paginate(filterValues);
                }}
            >
                <>
                    {pagination.items.length ?
                        pagination.items?.map((performer, index) => (
                            <tr key={index} data-row={index}>
                                <td className={'w-75'}>{performer.name}</td>
                                <td className={'admin-users-page__percent-td'}>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            percent: performer.percent
                                        }}
                                        validationSchema={PerformerFormValidationSchema}
                                        onSubmit={(value) => {
                                            handleOnPercentSet(index, performer, value);
                                        }}
                                    >
                                        <Form className={'admin-users-page__percent-form'}>
                                            <DefaultFormikInput
                                                name={'percent'}
                                                autocomplete={'off'}
                                                class={'admin-users-page__percent-input'}
                                            ></DefaultFormikInput>
                                            <button type="submit"
                                                    className="filters-button-apply admin-users-page__percent-button">
                                                Set
                                                <div className={'admin-users-page__percent-invisible'}>
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                </div>
                                            </button>
                                        </Form>
                                    </Formik>
                                </td>
                            </tr>
                        )) :
                        <tr>
                            <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>No results</td>
                        </tr>
                    }
                </>
            </AppTable>
        </Container>
    );
};

export default PerformersPage;
