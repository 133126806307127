import React, {FC, useEffect, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import DefaultFormikInput from '../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput';
import usePagination from '../../../../../helpers/UsePagination';
import AppTable from '../../../../../ui/Table/AppTable/AppTable';
import {mapped} from '../../../../../@api/Inventory';
import {Inventory} from '../../../../../models/Inventory';
import AppButton from '../../../../../ui/Buttons/AppButton/AppButton';
import AppSmartSearch from '../../../../../ui/Inputs/AppSmartSearch/AppSmartSearch';
import {performersNotPaginated} from '../../../../../@api/Performer';
import AppDatePicker from '../../../../../ui/Inputs/AppDatePicker/AppDatePicker';
import AppModal from '../../../../../ui/Modal/AppModal/AppModal';
import MapCategoriesModal from '../MapCategoriesModal/MapCategoriesModal';
import AppSelect from '../../../../../ui/Inputs/AppSelect/AppSelect';
import {venuesNotPaginated} from '../../../../../@api/Venue';
import IconYes from "../../../../../assets/images/other/check.svg";
import IconNo from "../../../../../assets/images/other/x-close.svg";
import "./MapCategoriesTable.scss";
import {handleReset} from "../../../../../helpers/Filters";

interface FilterProps {
    id: string,
    name: string,
    section: string,
    date: string,
    city: string,
    venue: string,
    vendor_name: string,
    performer: string,
    consignment: string,
    from_date: string,
    mapped: string,
    custom_pricing: string,
}

const selectItems = [
    {name: 'All Inventories', value: ''},
    {name: 'Unmapped on LTG', value: '!ltg'},
    {name: 'Unmapped on TC', value: '!ticombo'},
    {name: 'Unmapped on SH', value: '!stubhub'},
    {name: 'Mapped on LTG', value: 'ltg'},
    {name: 'Mapped on TC', value: 'ticombo'},
    {name: 'Mapped on SH', value: 'stubhub'},
];

const customPricingItems = [
    {name: 'Custom Pricing: All', value: ''},
    {name: 'Custom Price on TC', value: 'ticombo'},
    {name: 'Custom Price on LTG', value: 'ltg'},
    {name: 'No custom Price on TC', value: '!ticombo'},
    {name: 'No custom Price on LTG', value: '!ltg'},
];

const consignmentItems = [
    {name: 'All Consignment', value: ''},
    {name: 'Yes', value: 'Yes'},
    {name: 'No', value: 'No'}
];

const MapCategoriesTable: FC = () => {
    const filterInitial: FilterProps = {
        id: '',
        name: '',
        section: '',
        date: '',
        city: '',
        venue: '',
        vendor_name: '',
        performer: '',
        consignment: '',
        from_date: '',
        mapped: '',
        custom_pricing: '',
    };

    const tableHeaders = [
        'ID',
        'Vendor',
        'SB Event Name (Date & Time)',
        'Venue',
        'Section',
        'TC Event Name',
        'LTG event name',
        'SH event name',
        'Performer',
        'Consignment',
        'API keys status',
        'Map',
    ];

    const pagination = usePagination<Inventory>(mapped);
    const [map, setMap] = useState<Inventory | null>(null);
    const [filterValues, setFilterValues] = useState<FilterProps>(filterInitial);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [selectedPerPage, setSelectedPerPage] = useState<number>(50);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await pagination.paginate(filterValues);
    };

    const handlePageChange = async (page = selectedPage, perPage = selectedPerPage) => {
        pagination.setCurrentPage(page);
        pagination.setPerPage(perPage);
        await pagination.paginate(filterValues);
    };

    const handleMapClose = async () => {
        await handlePageChange();

        setMap(null);
    };

    const servicesIcons = [
        {name: 'TC', key: 'ticombo'},
        {name: 'SH', key: 'stubhub'}
    ];

    const handleSubmit = async (values: FilterProps) => {
        setFilterValues(values);
        pagination.setPerPage(selectedPerPage);
        setSelectedPage(1);
        pagination.setCurrentPage(1);
        await pagination.paginate(values);
    };

    return (
        <>
            <div className="mb-3 events-page__filters">
                <Formik
                    initialValues={filterInitial}
                    onSubmit={async (values: FilterProps) => handleSubmit(values)}
                >
                    {({values, resetForm}) =>
                        <Form>
                            <div className="events-page__filters__wrapper">
                                <div className={'narrow-input'}>
                                    <DefaultFormikInput
                                        name={'id'}
                                        placeholder={'Inventory ID'}
                                        autocomplete={'off'}
                                        class={'mb-2'}
                                    />
                                </div>
                                <DefaultFormikInput
                                    name={'name'}
                                    placeholder={'Event Name'}
                                    autocomplete={'off'}
                                    class={'mb-2'}
                                />
                                <DefaultFormikInput
                                    name={'section'}
                                    placeholder={'Inventory Section'}
                                    autocomplete={'off'}
                                    class={'mb-2'}
                                />
                                <div className={'narrow-input'}>
                                    <DefaultFormikInput
                                        name={'city'}
                                        placeholder={'Inventory City'}
                                        autocomplete={'off'}
                                        class={'mb-2'}
                                    />
                                </div>
                                <AppSmartSearch
                                    name={'venue'}
                                    placeholder={'Inventory Venue'}
                                    requestFunction={venuesNotPaginated}
                                    valuesKey={'venue'}
                                />
                                <div className="events-page__filters__component categories-table narrow-input">
                                    <label>
                                        <Field name={'with_api_keys'} type='checkbox'></Field>
                                        With API keys
                                    </label>
                                </div>
                            </div>
                            <div className="events-page__filters__wrapper">
                                <DefaultFormikInput
                                    name={'vendor_name'}
                                    placeholder={'Vendor'}
                                    autocomplete={'off'}
                                />
                                <AppSelect
                                    placeholder={'All Consignment'}
                                    value={values.consignment}
                                    name="consignment"
                                    items={consignmentItems}
                                />
                                <AppSelect
                                    placeholder={'All Inventories'}
                                    value={values.mapped}
                                    name="mapped"
                                    items={selectItems}
                                />
                                <AppSelect
                                    placeholder={'Custom Pricing: All'}
                                    value={values.custom_pricing}
                                    name="custom_pricing"
                                    items={customPricingItems}
                                />
                                <div className={'narrow-input'}>
                                    <AppDatePicker name={'from_date'} placeholder={'Date From'}/>
                                </div>
                                <div className={'narrow-input'}>
                                    <AppDatePicker name={'to_date'} placeholder={'Date To'}/>
                                </div>
                                <AppSmartSearch
                                    name={'performer'}
                                    placeholder={'Inventory Performer'}
                                    requestFunction={performersNotPaginated}
                                    valuesKey={'name'}
                                />
                                <div className="events-page__filters__component d-flex justify-content-end gap-2">
                                    <button type="reset"
                                            className="filters-button-reset"
                                            disabled={pagination.isLoading}
                                            onClick={async () => {
                                                await handleReset(resetForm, filterInitial, pagination, setFilterValues);
                                            }}
                                    >
                                        Reset
                                    </button>
                                    <button type="submit" className="filters-button-apply" disabled={pagination.isLoading}>
                                        Apply Filters
                                    </button>
                                </div>
                            </div>
                        </Form>
                    }
                </Formik>
            </div>

            <AppTable
                columns={tableHeaders}
                pagination={{
                    lastPage: pagination.lastPage,
                    currentPage: pagination.currentPage,
                    total_items: pagination.totalItems
                }}
                onPageChange={async (page, perPage) => {
                    setSelectedPage(page);
                    setSelectedPerPage(perPage);
                    await handlePageChange(page, perPage);
                }}
                isLoading={pagination.isLoading}
            >
                <>
                    {
                        pagination.items.map((inventory, index) => (
                            <tr className={'small-td'} key={index} data-row={index}>
                                <td>{inventory.inventory_id}</td>
                                <td>{inventory.sb_vendor.name}</td>
                                <td>{inventory.description} ({inventory.sb_event.occurs_at ? new Date(inventory.sb_event.occurs_at).toLocaleString() : '---'})</td>
                                <td>{inventory.sb_event.venue}</td>
                                <td>{inventory.section}</td>
                                <td>{inventory.sb_event.ticombo_event?.name}</td>
                                <td>{inventory.sb_event.ltg_event?.name}</td>
                                <td>{inventory.sb_event.stubhub_event?.name}</td>
                                <td>{inventory.sb_event.sb_performer.name}</td>
                                <td>{inventory.consignment}</td>
                                <td>
                                    <div className="sb-events-page__icons-wrapper">
                                        {servicesIcons.map((element, index) => (
                                            <div key={index}>{element.name}
                                                <img
                                                    data-map-image={inventory.id}
                                                    src={inventory.sb_vendor?.user?.api_keys ?
                                                        (inventory.sb_vendor.user.api_keys[element.key as keyof typeof inventory.sb_vendor.user.api_keys] ? IconYes : IconNo)
                                                        : IconNo
                                                    }
                                                    alt="event icon"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td>
                                    <div onClick={() => setMap(inventory)}>
                                        <AppButton text={'Map'}/>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </>
            </AppTable>

            <AppModal
                title={'Map'}
                customClass={'map-categories'}
                form={<MapCategoriesModal
                    inventory={map}
                    onClose={() => setMap(null)}
                    onMap={() => handleMapClose()}
                />
                }
                show={!!map}
                showHandle={() => setMap(null)}
                onClose={() => {
                }}/>
        </>
    );
};

export default MapCategoriesTable;
