import React, {FC, useEffect, useState} from "react";
import usePagination from "../../../helpers/UsePagination";
import {Invoice} from "../../../models/Invoice";
import {purchaseHistoryPaginateRequest} from "../../../@api/Invoice";
import {Accordion, Container} from "react-bootstrap";
import {Field, Form, Formik} from "formik";
import {handleOnDatesChange, handleOnFiltersChange, handleReset} from "../../../helpers/Filters";
import FormikDateInput from "../../../components/Inputs/FormikDateInput/FormikDateInput";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import {PaymentMethods} from "../../../enums/PaymentMethods";

interface FilterProps {
    date_from: string;
    date_to: string;
    sort_by: string;
}

const TicketExchangePurchaseHistory: FC = () => {
    const tableHeaders = [
        'Invoice Date',
        'Amount',
        'Quantity',
        'Buyer email',
        'Buyer name',
        'Seller email',
        'Seller name',
        'External Ref.',
        'Event Name',
        'Event Date',
        'Payment Method',
        'Public Notes',
        'Fulfillment Status'
    ];

    const filterInitial: FilterProps = {
        date_from: '',
        date_to: '',
        sort_by: 'ASC'
    };

    const dateFilters = [
        {name: 'date_from', placeholder: 'Date From'},
        {name: 'date_to', placeholder: 'Date To'},
    ];

    const [invoices, setInvoices] = useState<Invoice[]>([]);

    const pagination = usePagination<Invoice>(purchaseHistoryPaginateRequest);

    const [filterValues, setFilterValues] = useState<FilterProps>(filterInitial);

    useEffect(() => {
        pagination.paginate(filterInitial);
    }, []);

    useEffect(() => {
        setInvoices(pagination.items);
    }, [pagination.items]);

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>Ticket exchange purchase history</h1>
            <div className="mb-3">
                <Accordion>
                    <Accordion.Item eventKey={'0'}>
                        <Accordion.Header>
                            Filters
                        </Accordion.Header>
                        <Accordion.Body>
                            <Formik
                                initialValues={filterInitial}
                                onSubmit={async (values) => {
                                    await pagination.paginate(values);
                                }}
                            >
                                {({resetForm}) => {
                                    return (
                                        <Form className="table__filters"
                                              onChange={(event) => handleOnFiltersChange(event, filterValues, setFilterValues)}>
                                            <div className="table__filters__wrapper">
                                                <Field name={'sort_by'} as="select">
                                                    <option value="ASC">Sort by date ↓</option>
                                                    <option value="DESC">Sort by date ↑</option>
                                                </Field>
                                                {dateFilters.map((data, index) => (
                                                    <FormikDateInput
                                                        key={index + 'Dates'}
                                                        name={data.name}
                                                        placeholderText={data.placeholder}
                                                        showTimeSelect
                                                        autoComplete={'off'}
                                                        sendDate={(date: string, name: string) =>
                                                            handleOnDatesChange(date, name as keyof FilterProps, filterValues, setFilterValues)
                                                        }
                                                    />
                                                ))}
                                                <button type="reset"
                                                        className="filters-button-reset"
                                                        onClick={async () => {
                                                            await handleReset(resetForm, filterInitial, pagination, setFilterValues);
                                                        }}
                                                >
                                                    Reset
                                                </button>
                                                <button type="submit" className="filters-button-apply">
                                                    Apply Filters
                                                </button>
                                            </div>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <AppTable
                columns={tableHeaders}
                pagination={{
                    lastPage: pagination.lastPage,
                    currentPage: pagination.currentPage,
                    total_items: pagination.totalItems
                }}
                onPageChange={(page, perPage) => {
                    pagination.setCurrentPage(page);
                    pagination.setPerPage(perPage);
                    pagination.paginate(filterValues);
                }}
            >
                <>
                    {invoices.length ?
                        invoices.map((invoice, index) => (
                            <React.Fragment key={index}>
                                <tr className={"invoice-section-page__tr"} data-row={index}>
                                    <td>{dateToLocaleString(invoice.date)}</td>
                                    <td>{invoice.amount}</td>
                                    <td>{invoice.quantity}</td>
                                    <td>{invoice.customers ? invoice.customers.email : invoice.customer_email}</td>
                                    <td>{invoice.customers ? invoice.customers.display_name : invoice.customer_name}</td>
                                    <td>{invoice.users?.length ? invoice.users[0].email : invoice.customer_email}</td>
                                    <td>{invoice.users?.length ? invoice.users[0].name : invoice.customer_name}</td>
                                    <td>{invoice.external_reference || invoice.sb_invoice?.external_reference}</td>
                                    <td>{invoice.event_name}</td>
                                    <td>{dateToLocaleString(invoice.event_date)}</td>
                                    <td>{invoice.customers ? PaymentMethods[invoice.customers.payment_method as keyof typeof PaymentMethods] : invoice.sb_invoice?.payment_method}</td>
                                    <td>{invoice.public_notes}</td>
                                    <td></td>
                                </tr>
                            </React.Fragment>
                        )) :
                        <tr>
                            <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>No results</td>
                        </tr>
                    }
                </>
            </AppTable>
        </Container>
    )
}

export default TicketExchangePurchaseHistory;
