import "./AppLoader.scss";
import React, {FC} from "react";

interface Props {
    fullHeight?: boolean
}

const AppLoader: FC<Props> = ({fullHeight}) => {
    return (
        <div className={"app-loader-wrapper" + (fullHeight ? ' h-100': '')}>
            <span className="app-loader"></span>
        </div>
    );
}

export default AppLoader;
