import React, {FC} from "react";
import {FormikValues} from "formik";
import {dateToLocaleString} from "../../../../../helpers/DateFormatter";
import {MappedEntitiesEnum} from "../../../../../enums/MappedEntitesEnum";
import {Log} from "../../../../../models/Log";
import AppTable from "../../../../../ui/Table/AppTable/AppTable";

interface Props {
    pagination: {
        paginate: (params?: any) => Promise<void>,
        items: Log[],
        setItems: React.Dispatch<React.SetStateAction<Log[]>>,
        currentPage: number,
        setCurrentPage: (v: number) => void,
        totalItems: number,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>,
        lastPage: number,
        perPage: number,
        setPerPage: (v: number) => void,
        isLoading: boolean,
        error: string
    };
    selectedPage: number;
    filterValues: FormikValues;
}

const SbEventReportsTable: FC<Props> = ({pagination, selectedPage, filterValues}) => {
    const tableHeaders = [
        'Email',
        'SB ID',
        'SB Name',
        'LTG name',
        'Ticombo name',
        'Stubhub name',
        'Mapped at'
    ];

    return (
        <AppTable
            columns={tableHeaders}
            pagination={{
                lastPage: pagination.lastPage,
                currentPage: pagination.currentPage,
                total_items: pagination.totalItems
            }}
            onPageChange={(page, perPage) => {
                pagination.setCurrentPage(page);
                pagination.setPerPage(perPage);
                pagination.paginate(filterValues);
            }}
            withAutoHeight={false}
        >
            <>
                {pagination.items.length
                    ? pagination.items?.map((event, index) =>
                        <React.Fragment key={'SbEvents' + index}>
                            <tr data-row={index}>
                                <td>{event.user.email}</td>
                                <td>
                                    {event.loggable_id}
                                </td>
                                <td>
                                    {
                                        event.meta?.sb_event
                                            ? event.meta.sb_event.name + ', '
                                            + event.meta.sb_event.venue + ' '
                                            + dateToLocaleString(event.meta.sb_event.occurs_at)
                                            : '-'
                                    }
                                </td>
                                <td>
                                    {(event.action_type === MappedEntitiesEnum.unmapLtgEvent)
                                        ? 'Unmapped'
                                        : ((event.action_type === MappedEntitiesEnum.mapLtgEvent && event.meta?.ltg_event)
                                                ? event.meta.ltg_event.name + ' '
                                                + dateToLocaleString(event.meta.ltg_event.start_date_time)
                                                : '-'
                                        )
                                    }
                                </td>
                                <td>
                                    {(event.action_type === MappedEntitiesEnum.unmapTicomboEvent)
                                        ? 'Unmapped'
                                        : ((event.action_type === MappedEntitiesEnum.mapTicomboEvent && event.meta?.ticombo_event)
                                                ? event.meta.ticombo_event.name + ' '
                                                + dateToLocaleString(event.meta.ticombo_event.date.start)
                                                : '-'
                                        )
                                    }
                                </td>
                                <td>
                                    {(event.action_type === MappedEntitiesEnum.unmapStubhubEvent)
                                        ? 'Unmapped'
                                        : ((event.action_type === MappedEntitiesEnum.mapStubhubEvent && event.meta?.stubhub_event)
                                                ? event.meta.stubhub_event.name + ' '
                                                + dateToLocaleString(event.meta.stubhub_event.event_date_local)
                                                : '-'
                                        )
                                    }
                                </td>
                                <td>{dateToLocaleString(event.created_at)}</td>
                            </tr>
                        </React.Fragment>
                    )
                    :
                    <tr className={"events-page__table__tr "}>
                        <td className={"text-center"} colSpan={6}>No events</td>
                    </tr>
                }
            </>
        </AppTable>
    )
}

export default SbEventReportsTable;
