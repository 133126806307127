import {Container} from "react-bootstrap";
import MapCategoriesTable from "./components/MapCategoriesTable/MapCategoriesTable";

const CategoriesPage = () => {
    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>Categories</h1>
                    <div className="events-page position-static pb-0">
                        <MapCategoriesTable/>
                    </div>
        </Container>
    );
}

export default CategoriesPage;
