import React, {FC} from "react";
import {FormikValues} from "formik";
import {Log} from "../../../../../models/Log";
import {dateToLocaleString} from "../../../../../helpers/DateFormatter";
import IconYes from "../../../../../assets/images/other/check.svg";
import IconNo from "../../../../../assets/images/other/x-close.svg";
import AppTable from "../../../../../ui/Table/AppTable/AppTable";

interface Props {
    pagination: {
        paginate: (params?: any) => Promise<void>,
        items: Log[],
        setItems: React.Dispatch<React.SetStateAction<Log[]>>,
        currentPage: number,
        setCurrentPage: (v: number) => void,
        totalItems: number,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>,
        lastPage: number,
        perPage: number,
        setPerPage: (v: number) => void,
        isLoading: boolean,
        error: string
    };
    selectedPage: number;
    filterValues: FormikValues;
}

const SbInventoryReportsTable: FC<Props> = ({pagination, selectedPage, filterValues}) => {
    const tableHeaders = [
        'Email',
        'SB ID',
        'SB Name',
        'SB Section',
        'LTG Name',
        'Ticombo Name',
        'Stubhub Name',
        'Mapped At'
    ];

    return (
        <AppTable
            columns={tableHeaders}
            pagination={{
                lastPage: pagination.lastPage,
                currentPage: pagination.currentPage,
                total_items: pagination.totalItems
            }}
            onPageChange={(page, perPage) => {
                pagination.setCurrentPage(page);
                pagination.setPerPage(perPage);
                pagination.paginate(filterValues);
            }}
            withAutoHeight={false}
        >
            <>
                {pagination.items.length
                    ? pagination.items?.map((event, index) =>
                        <React.Fragment key={'SbEvents' + index}>
                            <tr data-row={index}>
                                <td>{event.user.email}</td>
                                <td>
                                    {event.loggable_id}
                                </td>
                                <td>
                                    {
                                        event.meta?.sb_inventory
                                            ? event.meta.sb_inventory.sb_event?.name + ', '
                                            + event.meta.sb_inventory.sb_event?.venue + ' '
                                            + dateToLocaleString(event.meta.sb_inventory.sb_event?.occurs_at)
                                            : '-'
                                    }
                                </td>
                                <td>
                                    {event.meta?.sb_inventory?.section}
                                </td>

                                <td>
                                    {(event.meta?.ltg_inventory
                                            ? (
                                                (event.meta.ltg_inventory.ltg_section ?
                                                        'Section: ' + event.meta.ltg_inventory.ltg_section.name
                                                        : ''
                                                ) ||
                                                (event.meta.ltg_inventory.ltg_location_category ?
                                                        'Location category: ' + event.meta.ltg_inventory.ltg_location_category.name
                                                        : ''
                                                )
                                            )
                                            : '-'
                                    )}
                                </td>
                                <td>
                                    {event.meta?.ticombo_inventory ?
                                        <>
                                            <span>{'Category: ' + event.meta.ticombo_inventory.category}</span>
                                            <br/>
                                            <div>
                                                <img
                                                    src={event.meta.ticombo_inventory.set_section_status ? IconYes : IconNo}
                                                    alt="event icon"/>
                                                {
                                                    event.meta.ticombo_inventory.set_section_status ?
                                                        ' I know my exact seat location' :
                                                        ' I do not know exact seat location'
                                                }
                                            </div>
                                        </>
                                        : '-'
                                    }
                                </td>
                                <td>
                                    {event.meta?.stubhub_section
                                        ? event.meta.stubhub_section.name
                                        : '-'
                                    }
                                </td>
                                <td>
                                    {dateToLocaleString(event.created_at)}
                                </td>
                            </tr>
                        </React.Fragment>
                    )
                    :
                    <tr className={"events-page__table__tr "}>
                        <td className={"text-center"} colSpan={7}>No inventories</td>
                    </tr>
                }
            </>
        </AppTable>
    )
}

export default SbInventoryReportsTable;
