import {Form, Formik, FormikValues} from "formik";
import React, {FC, useEffect, useRef, useState} from "react";
import usePagination from "../../../../../helpers/UsePagination";
import {sbEventsPaginateRequest} from "../../../../../@api/Event";
import Table from "react-bootstrap/Table";
import './WantedTickets.scss'
import {NewWantedEvent, WantedEvent} from "../../../../../models/WantedEvent";
import DefaultFormikInput from "../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import {SbEvent} from "../../../../../models/Event";
import AppModal from "../../../../../ui/Modal/AppModal/AppModal";
import FormikDateInput from "../../../../../components/Inputs/FormikDateInput/FormikDateInput";
import MatchCard from "../../../SellPage/components/MatchCard/MatchCard";
import {
    buy,
    createWantedEvent,
    deleteEvent,
    getSuitableInventories,
    getWantedEvents
} from "../../../../../@api/WantedEvent";
import WantedTicketsForm from "./WantedTicketsForm/WantedTicketsForm";
import {useDynamicSortOptions} from "../../../../../hooks/useDynamicSortOptions";
import {SbEventsFilters} from "../../../../../@interfaces/SbEventsFilters";
import useHandleSbEventsPaginationScroll from "../../../../../hooks/useHandleSbEventsPaginationScroll";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/Store";
import LinkInventoryForm from "../../../BidsPage/components/LinkInventoryForm/LinkInventoryForm";
import {HttpFailureResponse} from "../../../../../@api/Responses/HttpFailureResponse";
import useWindowWidth from "../../../../../hooks/useHandleWindowResize";
import {Inventory} from "../../../../../models/Inventory";
import {MOBILE_WIDTH} from "../../../../../constants/Layout";
import {dateToLocaleString} from "../../../../../helpers/DateFormatter";
import {HttpSuccessResponse} from "../../../../../@api/Responses/HttpSuccessResponse";
import AppLoader from "../../../../../ui/Loader/AppLoader/AppLoader";

const WantedTickets: FC = () => {
    const pagination = usePagination<SbEvent>(sbEventsPaginateRequest);
    const windowWidth = useWindowWidth();
    const ref = useRef<null | any>(null);
    const loggedUser = useSelector((state: RootState) => state.auth.user);

    const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(true);
    const [events, setEvents] = useState<SbEvent[]>([])
    const [isWantedEventModalOpen, setIsWantedEventModalOpen] = useState(false);
    const [isLinkInventoryModalOpen, setIsLinkInventoryModalOpen] = useState(false);
    const [wantedEvents, setWantedEvents] = useState<WantedEvent[]>([]);
    const [selectedEventForOrder, setSelectedEventForOrder] = useState<number | null>(null);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const [suitableInventories, setSuitableInventories] = useState<Inventory[]>([]);
    const [eventForModal, setEventForModal] = useState<SbEvent | undefined>(undefined)
    const [selectedEventId, setSelectedEventId] = useState<number | null>(null);
    const [eventEntity, setEventEntity] = useState<WantedEvent | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const filterInitial: SbEventsFilters = {
        city: '',
        name: '',
        venue: '',
        from_date: new Date()
    };

    const handleScroll = useHandleSbEventsPaginationScroll(ref, filterInitial);
    const {setSortOptions, sortFunction} = useDynamicSortOptions();

    useEffect(() => {
        setEvents([...events, ...pagination.items]);
    }, [pagination.items]);

    useEffect(() => {
        pagination.paginate();
        getAllWantedEvents();
    }, []);

    useEffect(() => {
        if (windowWidth <= 1024) {
            setIsOpenSidebar(false)
        } else {
            setIsOpenSidebar(true)
        }
    }, [windowWidth]);

    const formValues = [
        {name: 'name', placeholder: 'Search tickets by name'},
        {name: 'city', placeholder: 'Search tickets by city'},
        {name: 'venue', placeholder: 'Search tickets by venue'},
    ];

    const dateValues = [
        {name: 'from_date', placeholder: 'From date'},
        {name: 'to_date', placeholder: 'To date'},
    ];

    const openWantedEventModal = (data: SbEvent) => {
        setSelectedEventId(data.id);
        setEventForModal(data)

        setIsWantedEventModalOpen(!isWantedEventModalOpen);
    };

    const openOrderModal = async (data: WantedEvent) => {
        setSelectedEventForOrder(data.id);
        setEventEntity(data);

        const res = await getSuitableInventories(data.id);

        setSuitableInventories(res.data);
        setIsLinkInventoryModalOpen(!isLinkInventoryModalOpen);
    }

    const resetFilterForm = async () => {
        setIsBtnDisabled(true);

        await handleScroll.resetEvents();

        setIsBtnDisabled(false);
    };

    const searchMatch = (event: FormikValues) => {
        handleScroll.filterEvents(event);
    }

    const handleCloseWantedEventModal = () => {
        setIsWantedEventModalOpen(false);
        setSelectedEventId(null);
    }

    const handleCloseLinkInventoryModal = () => {
        setIsLinkInventoryModalOpen(false);
    }

    const handleSubmitWantedEventForm = async (values: NewWantedEvent) => {
        const res = await createWantedEvent({sb_event_id: selectedEventId!, ...values});

        if (res instanceof HttpSuccessResponse) {
            await getAllWantedEvents();
            handleCloseWantedEventModal();
        }
    };

    const handleSubmitLinkInventoryForm = async (values: { inventory_id: number }) => {
        const res = await buy(selectedEventForOrder!, values.inventory_id);

        if (res instanceof HttpFailureResponse) {
            alert(res.message);
            return
        }

        handleCloseLinkInventoryModal();
        await getAllWantedEvents();
    };

    const getAllWantedEvents = async () => {
        const response = await getWantedEvents();

        if (response) {
            setIsLoading(false);
        }

        setWantedEvents(response.data[0]);
    };

    const isEventMine = (event: WantedEvent): boolean => {
        return event.user_id === loggedUser?.id;
    };

    const deleteWantedEvent = async (id: number) => {
        setIsBtnDisabled(true);
        await deleteEvent(id);
        setIsBtnDisabled(false);
        await getAllWantedEvents();
    }

    return (
        <>
            {isOpenSidebar && windowWidth <= 1024 ? (
                <div className="buy-page-overlay"></div>
            ) : null}
            <div className={`${windowWidth > MOBILE_WIDTH ? 'buy-page-container' : 'buy-page-container_mobile'}`}>
                <div className="find-events-sidebar">
                    <div className="find-events-sidebar-toggle" onClick={() => setIsOpenSidebar(!isOpenSidebar)}>
                        {isOpenSidebar ? <span>&#171;</span> : <span>&#187;</span>}
                    </div>
                    <div className={isOpenSidebar ? 'find-events find-events-expand' : 'find-events-collapse'}>
                        {
                            isOpenSidebar
                                ? <>
                                    <div className="matches matches-add-inventory">
                                        <div className="matches-filters">
                                            <div className="matches-header matches-header-add-inventory">
                                                <h3 className="matches-title">
                                                    Matches
                                                </h3>
                                                <select
                                                    onChange={(e) => setSortOptions(e.target.value)}
                                                    defaultValue={'occurs_at'}
                                                    className="matches-sort"
                                                >
                                                    <option value="occurs_at">Sort by date &darr;</option>
                                                    <option value="-occurs_at">Sort by date &uarr;</option>
                                                    <option value="name">Sort by name &darr;</option>
                                                    <option value="-name">Sort by name &uarr;</option>
                                                </select>
                                            </div>
                                            <hr/>
                                            <Formik
                                                initialValues={filterInitial}
                                                onSubmit={async (values) => {
                                                    const data = {
                                                        ...values
                                                    } as any;
                                                    await searchMatch(data);
                                                }}
                                                innerRef={ref}
                                            >
                                                {({isSubmitting, values, handleChange}) =>
                                                    <Form className="filters-form">
                                                        {
                                                            formValues.map((formValue, index) => (
                                                                <DefaultFormikInput
                                                                    key={formValue.name + index}
                                                                    name={formValue.name}
                                                                    placeholder={formValue.placeholder}
                                                                    autocomplete={'off'}
                                                                    class={'filters-search'}
                                                                ></DefaultFormikInput>
                                                            ))
                                                        }
                                                        <div className="filters-date">
                                                            {
                                                                dateValues.map((dateValue, index) => (
                                                                    <div>
                                                                        <>
                                                                            <FormikDateInput
                                                                                key={dateValue.name + index}
                                                                                name={dateValue.name}
                                                                                placeholderText={dateValue.placeholder}
                                                                                autoComplete={'off'}
                                                                                className={''}
                                                                            />
                                                                        </>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className="filters-button">
                                                            <button type='button' onClick={() => resetFilterForm()}
                                                                    className="filters-button-reset"
                                                                    disabled={isBtnDisabled}>
                                                                Reset
                                                            </button>
                                                            <button type="submit" className="filters-button-apply">
                                                                Apply Filters
                                                            </button>
                                                        </div>
                                                    </Form>
                                                }
                                            </Formik>
                                        </div>
                                        <div
                                            className="matches matches-list matches-list-add-inventory matches-list-add-inventory_small"
                                            onScroll={handleScroll.handleChange}>
                                            {
                                                handleScroll.events ? handleScroll.events.slice().sort(sortFunction).map((data, index) =>
                                                        <MatchCard key={data.event_id + index} event={data}
                                                                   clickFunc={() => openWantedEventModal(data)}
                                                                   isSelected={selectedEventId === data.id}
                                                        />
                                                    ) :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                        }
                    </div>
                </div>
                {windowWidth <= MOBILE_WIDTH &&
                    <>
                        <div className="matches matches-add-inventory">
                            <div className="matches-header matches-header-add-inventory">
                                <h3 className="matches-title matches-title_mobile">
                                    Matches
                                </h3>
                            </div>
                            <hr/>
                            <div className="matches-body">
                                <Formik
                                    initialValues={filterInitial}
                                    onSubmit={async (values) => {
                                        const data = {
                                            ...values
                                        } as any;
                                        await searchMatch(data);
                                    }}
                                    innerRef={ref}
                                >
                                    {({isSubmitting, values, handleChange}) =>
                                        <Form className="filters-form">
                                            {
                                                formValues.map((formValue, index) => (
                                                    <DefaultFormikInput
                                                        key={formValue.name + index}
                                                        name={formValue.name}
                                                        placeholder={formValue.placeholder}
                                                        autocomplete={'off'}
                                                        class={'filters-search'}
                                                    ></DefaultFormikInput>
                                                ))
                                            }
                                            <div className="filters-date">
                                                {
                                                    dateValues.map((dateValue, index) => (
                                                        <div key={dateValue.name + index}>
                                                            <>
                                                                <FormikDateInput

                                                                    name={dateValue.name}
                                                                    placeholderText={dateValue.placeholder}
                                                                    autoComplete={'off'}
                                                                    className={''}
                                                                />
                                                            </>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                            <div className="filters-button">
                                                <button type='button' onClick={() => resetFilterForm()}
                                                        className="filters-button-reset"
                                                        disabled={isBtnDisabled}>
                                                    Reset
                                                </button>
                                                <button type="submit" className="filters-button-apply">
                                                    Apply Filters
                                                </button>
                                            </div>
                                        </Form>
                                    }
                                </Formik>

                                <div className="matches matches-list matches-list-add-inventory"
                                     onScroll={handleScroll.handleChange}>
                                    {
                                        handleScroll.events && handleScroll.events.slice().sort(sortFunction).map((data, index) =>
                                            <MatchCard key={data.event_id + index} event={data}
                                                       clickFunc={() => openWantedEventModal(data)}
                                                       isSelected={selectedEventId === data.id}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
                {/*Таблица*/}
                {!isLoading ?
                    <div className={'wanted-table-container'}>

                        <Table>
                            <thead className={'wanted-tickets__thead sticky-header'}>
                            <tr>
                                <th>#</th>
                                <th>City</th>
                                <th>Venue</th>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Ready to buy</th>
                                <th>Quantity</th>
                                <th>Single seats</th>
                                <th>Section/Category</th>
                                <th>Row</th>
                                <th>Notes</th>
                                <th>Delivery method</th>
                                <th>Split type</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody className={'wanted-tickets__tbody'}>
                            {wantedEvents.length ? wantedEvents.map((event, index) =>
                                <>
                                    <tr key={event.id + index} data-row={index} className={"wanted-tickets__tr"}>
                                        <td className={'wanted-tickets__td'}>{index + 1}</td>
                                        <td className={'wanted-tickets__td'}>{event.sb_event.city}</td>
                                        <td className={'wanted-tickets__td'}>{event.sb_event.venue}</td>
                                        <td className={'wanted-tickets__td'}>{dateToLocaleString(event.sb_event.occurs_at)}</td>
                                        <td className={'wanted-tickets__td'}>{event.sb_event.name}</td>
                                        <td className={'wanted-tickets__td'}>
                                            {event.ready_to_buy === 'Ready'
                                                ? `${event.currency_icon || '$'} ${event.price}`
                                                : '-'
                                            }
                                        </td>
                                        <td className={'wanted-tickets__td'}>{event.ready_to_buy}</td>
                                        <td className={'wanted-tickets__td'}>{event.quantity}</td>
                                        <td className={'wanted-tickets__td'}>{event.single_seats !== 0
                                            ? event.single_seats
                                            : '-'
                                        }
                                        </td>
                                        <td className="hover-text wanted-tickets__td ">
                                            <div className="text-container">{event.section_or_category}</div>
                                            {event.section_or_category &&
                                                <span
                                                    className="tooltip-text wanted-ticket-tooltip">
                                                {event.section_or_category}
                                            </span>
                                            }
                                        </td>
                                        <td className="hover-text wanted-tickets__td ">
                                            <div className="text-container">{event.row || '-'}</div>
                                            {event.row &&
                                                <span className="tooltip-text wanted-ticket-tooltip">{event.row}</span>
                                            }
                                        </td>
                                        <td className="hover-text wanted-tickets__td ">
                                            <div className="text-container">{event.notes || '-'}</div>
                                            {event.notes &&
                                                <span
                                                    className="tooltip-text wanted-ticket-tooltip">{event.notes}</span>
                                            }
                                        </td>
                                        <td className={'wanted-tickets__td'}>
                                            {event.delivery_method
                                                ? event.delivery_method
                                                : '-'
                                            }
                                        </td>
                                        <td className={'wanted-tickets__td'}>{event.split_type || '-'}</td>
                                        {isEventMine(event) ?
                                            <td className={'wanted-tickets__td'}>
                                                <button
                                                    type="button"
                                                    disabled={isBtnDisabled}
                                                    onClick={() => deleteWantedEvent(event.id)}
                                                    className={'wanted-tickets__button wanted-tickets__button_delete'}>Delete
                                                </button>
                                            </td>
                                            :
                                            <td className={'wanted-tickets__td'}>
                                                <button
                                                    type="button"
                                                    disabled={isBtnDisabled}
                                                    onClick={() => openOrderModal(event)}
                                                    className={'wanted-tickets__button wanted-tickets__button_offer'}>Accept
                                                    order
                                                </button>
                                            </td>
                                        }
                                    </tr>
                                </>
                            ) : (<tr>
                                    <td className={'wanted-tickets__td w-100 no-border'}
                                        style={{backgroundColor: 'white', paddingTop: '30px'}}
                                        colSpan={14}>Here are no wanted tickets right now...
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>

                    </div>
                    :
                    <div className={'loader-container'}>
                        <AppLoader/>
                    </div>
                }
            </div>
            <AppModal
                centered={true}
                headerTitleClassName={'buy-inventory-header'}
                bodyClassName={'buy-inventory-modal'}
                title={'Add Needed Inventory'}
                form={
                    <WantedTicketsForm
                        event={eventForModal}
                        onClose={handleCloseWantedEventModal}
                        onSubmit={(values) => handleSubmitWantedEventForm(values)}
                    />
                }
                show={isWantedEventModalOpen}
                showHandle={handleCloseWantedEventModal}
                onClose={() => handleCloseWantedEventModal}
            />
            <AppModal
                centered={true}
                title={'Accept order'}
                headerTitleClassName={'buy-inventory-header'}
                bodyClassName={'buy-inventory-modal'}
                headerStyle={{border: 'none'}}
                form={
                    <LinkInventoryForm
                        onClose={handleCloseLinkInventoryModal}
                        wantedEvent={eventEntity!}
                        inventories={suitableInventories}
                        onSubmit={(values) =>
                            handleSubmitLinkInventoryForm({inventory_id: parseInt(values.inventory_id as string)}
                            )}
                    />
                }
                show={isLinkInventoryModalOpen}
                showHandle={handleCloseLinkInventoryModal}
                onClose={() => handleCloseLinkInventoryModal}
            />
        </>
    )
}

export default WantedTickets;
