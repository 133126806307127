import React, {FC, useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {Field, Form, Formik} from "formik";
import usePagination from '../../../helpers/UsePagination';
import {Vendor, VendorEdit} from "../../../models/Vendor";
import CheckIcon from '../../../assets/images/admin/check-icon.png';
import {citiesNotPaginated, updateVendor, vendorsNotPaginated, vendorsPaginateRequest} from "../../../@api/Vendor";
import PhoneNumberModal from "./PhoneNumberModal/PhoneNumberModal";
import Button from "react-bootstrap/Button";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import {VendorTypes} from "../../../enums/Skybox/VendorTypes";
import {handleOnFiltersChange, handleReset} from "../../../helpers/Filters";
import AppSmartSearch from "../../../ui/Inputs/AppSmartSearch/AppSmartSearch";
import AppButton from "../../../ui/Buttons/AppButton/AppButton";
import AppModal from "../../../ui/Modal/AppModal/AppModal";
import EditVendorForm from "./EditVendorForm/EditVendorForm";
import './EditVendorForm/EditVendorForm.scss'
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import {StateTypes} from "../../../enums/Skybox/StateTypes";
import DefaultFormikInput from "../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";

interface FilterProps {
    name: string;
    type: string;
    lastName: string;
    city: string;
    vendorId: string;
}

const VendorsPage: FC = () => {
    const tableHeaders = [
        'ID',
        'Name',
        'Type',
        'Email',
        'Phone number',
        'Company',
        'Company address',
        'City',
        'State',
        'Tags',
        'User exists',
        ''
    ];

    const filterInitial: FilterProps = {
        name: '',
        type: '',
        lastName: '',
        city: '',
        vendorId: ''
    };

    const [phoneNumberModalId, setPhoneNumberModalId] = useState<number | null>(null);
    const [filterValues, setFilterValues] = useState<FilterProps>(filterInitial);
    const [selectedVendorId, setSelectedVendorId] = useState<number | undefined>(undefined);

    const pagination = usePagination<Vendor>(vendorsPaginateRequest);

    useEffect(() => {
        pagination.paginate(filterInitial);
    }, []);

    const phoneNumber = async (id: number) => {
        setPhoneNumberModalId(id);
    };

    const closeEditModal = () => {
        setSelectedVendorId(undefined);
    }

    const handleEditVendor = async (values: VendorEdit, id: number) => {
        const res = await updateVendor(values, id);

        if (res instanceof HttpSuccessResponse) {
            pagination.paginate();
            closeEditModal();
        }
    }

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>Vendors</h1>

            <div className="mb-3">
                <Formik
                    initialValues={filterInitial}
                    onSubmit={async (values) => {
                        pagination.setPerPage(pagination.perPage);
                        await pagination.paginate(values);
                    }}
                >
                    {({resetForm}) => {
                        return (
                            <Form className="table__filters"
                                  onChange={(event) => handleOnFiltersChange(event, filterValues, setFilterValues)}>
                                <div className={"table__filters__wrapper-column"}>
                                    <div className="table__filters__wrapper">
                                        <AppSmartSearch
                                            name={'name'}
                                            placeholder={'Search vendor'}
                                            requestFunction={vendorsNotPaginated}
                                            valuesKey={'name'}
                                        />
                                        <DefaultFormikInput
                                            name={'lastName'}
                                            placeholder={'Search users by last name'}
                                            autocomplete={'off'}
                                            class={'mb-2'}
                                        ></DefaultFormikInput>
                                        <DefaultFormikInput
                                            name={'vendorId'}
                                            placeholder={'Search users by vendor ID'}
                                            autocomplete={'off'}
                                            class={'mb-2'}
                                        ></DefaultFormikInput>
                                    </div>
                                    <div className="table__filters__wrapper">
                                        <AppSmartSearch
                                            name={'city'}
                                            placeholder={'Search by city'}
                                            requestFunction={citiesNotPaginated}
                                            valuesKey={'city'}
                                        />
                                        <Field name={'type'} as="select">
                                            <option value="">Type</option>
                                            {Object.entries(VendorTypes).map((key) => (
                                                <option key={key[0]} value={key[0]}>{key[1]}</option>
                                            ))}
                                        </Field>
                                        <button type="reset"
                                                className="filters-button-reset"
                                                onClick={async () => {
                                                    await handleReset(resetForm, filterInitial, pagination, setFilterValues);
                                                }}
                                        >
                                            Reset
                                        </button>
                                        <button type="submit" className="filters-button-apply">
                                            Apply Filters
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>

            <AppTable
                columns={tableHeaders}
                pagination={{
                    lastPage: pagination.lastPage,
                    currentPage: pagination.currentPage,
                    total_items: pagination.totalItems
                }}
                onPageChange={(page, perPage) => {
                    pagination.setCurrentPage(page);
                    pagination.setPerPage(perPage);
                    pagination.paginate(filterValues);
                }}
            >
                <>
                    {pagination.items.length ?
                        pagination.items?.map((vendor, index) => (
                            <React.Fragment key={index}>
                                <tr className={'admin-users-page__tr'} data-row={index}>
                                    <td>{vendor.id}</td>
                                    <td>{vendor.name}</td>
                                    <td>{VendorTypes[vendor.type as keyof typeof VendorTypes]}</td>
                                    <td>{vendor.email}</td>
                                    <td>{vendor.phone_number || '-'}</td>
                                    <td>{vendor.company_name || '-'}</td>
                                    <td>{vendor.company_address || '-'}</td>
                                    <td>{vendor.city || '-'}</td>
                                    <td>{StateTypes[vendor.state as keyof typeof StateTypes]}</td>
                                    <td>{vendor.vendor_tags || '-'}</td>
                                    <td>
                                        {
                                            vendor.status === 1 ?
                                                <img src={CheckIcon}
                                                     alt="action icon"
                                                     draggable="false"
                                                /> :
                                                <Button
                                                    className="filters-button-apply action-button"
                                                    onClick={() => phoneNumber(vendor.id)}>
                                                    Invite
                                                </Button>
                                        }
                                    </td>
                                    <td>
                                        <div onClick={() => {
                                            setSelectedVendorId(vendor.id)
                                        }}>
                                            <AppButton text={'Edit'} color={"red"} type={"button"}></AppButton>
                                        </div>
                                    </td>
                                </tr>
                                <PhoneNumberModal key={'modal' + vendor.id} visible={phoneNumberModalId === vendor.id}
                                                  vendor={vendor}
                                                  onClose={() => {
                                                      setPhoneNumberModalId(null);
                                                  }}
                                />
                                <AppModal
                                    customClass={'vendor-edit-modal'}
                                    centered={true}
                                    title={'Edit vendor'}
                                    form={
                                        <EditVendorForm
                                            onSubmit={(values) => handleEditVendor(values, selectedVendorId!)}
                                            vendor={vendor}
                                        />}
                                    show={selectedVendorId === vendor.id}
                                    showHandle={closeEditModal}
                                    onClose={() => {
                                    }}
                                />
                            </React.Fragment>
                        )) :
                        <tr>
                            <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>No results</td>
                        </tr>
                    }
                </>
            </AppTable>
        </Container>
    );
};

export default VendorsPage;
