import React, {FC, useEffect, useState} from "react";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import usePagination from "../../../helpers/UsePagination";
import {Container, Dropdown} from "react-bootstrap";
import {holdsPaginate} from "../../../@api/Inventory";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import ActionIconDark from "../../../assets/images/other/action-icon-dark.png";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import HoldForm from "../../../components/SellPage/HoldForm/HoldForm";
import AppModal from "../../../ui/Modal/AppModal/AppModal";
import {SbHold} from "../../../models/SbHold";
import {removeHold} from "../../../@api/Holds";
import {useNavigate} from "react-router-dom";
import AppLoader from "../../../ui/Loader/AppLoader/AppLoader";

const HoldsPage: FC = () => {
    const [selectedHold, setSelectedHold] = useState<SbHold | undefined>(undefined);
    const [showHold, setShowHold] = useState<number | null>(null);

    const tableHeaders = [
        'Event',
        'Event Date',
        'Customer',
        'Venue',
        'City',
        'QTY',
        'Section',
        'Row',
        'Seats',
        'Total Cost',
        'Unit Price',
        'Held By',
        'Held On',
        'Held Until',
        'External reference',
        'Inventory ID',
        'Notes',
        'Actions'
    ];

    const pagination = usePagination<SbHold>(holdsPaginate);
    const navigate = useNavigate();

    useEffect(() => {
        pagination.paginate();
    }, []);

    const unhold = async (hold: SbHold) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure you want to remove hold?')) {
            const res = await removeHold(hold.id);

            if (res instanceof HttpSuccessResponse) {
                pagination.paginate();
                alert('Hold removed!');
            }
        }
    };

    const editHold = async (hold: SbHold) => {
        setSelectedHold(hold);
        setShowHold(hold.id);
    };

    const handleHoldClose = () => {
        setShowHold(null);
        pagination.paginate();
    };

    const handleCreateInvoiceClick = (hold: SbHold) => {
        navigate(`/user/invoices/new?inventoryId=${hold.sb_inventory.id}`, {
            state: {inventoryData: hold.sb_inventory, customer: hold.customer}
        });
    };

    return (
        <Container className={'admin-users-page events'}>
            <div className={"teammates__head"}>
                <h1 className={"admin-users-page__title"}>Holds</h1>
            </div>
            {!pagination.isLoading ?
                <AppTable
                    columns={tableHeaders}
                    pagination={{
                        lastPage: pagination.lastPage,
                        currentPage: pagination.currentPage,
                        total_items: pagination.totalItems
                    }}
                    onPageChange={(page, perPage) => {
                        pagination.setCurrentPage(page);
                        pagination.setPerPage(perPage);
                        pagination.paginate();
                    }}>
                    <>
                        {pagination.items.length ? (
                                pagination.items?.map((hold, index) => (
                                    <tr data-row={index} key={hold.id + index}>
                                        <td>{hold?.sb_event?.name}</td>
                                        <td>{hold?.sb_event?.occurs_at ? dateToLocaleString(hold?.sb_event?.occurs_at) : ''}</td>
                                        <td>{hold?.customer?.email ? hold.customer.email : ''}</td>
                                        <td>{hold?.sb_event?.venue}</td>
                                        <td>{hold?.sb_event?.city}</td>
                                        <td>{hold?.quantity}</td>
                                        <td>{hold?.section}</td>
                                        <td>{hold?.row}</td>
                                        <td>{hold?.seats}</td>
                                        <td>{hold?.sb_inventory?.currency_icon}{hold?.sb_inventory.unit_amount * Number(hold?.quantity)}</td>
                                        <td>{hold?.sb_inventory?.currency_icon}{Number(hold?.sb_inventory.unit_amount)}</td>
                                        <td>{hold?.user?.name}</td>
                                        <td>{dateToLocaleString(String(hold?.created_at))}</td>
                                        <td>{dateToLocaleString(String(hold?.expiry_date))}</td>
                                        <td>{hold?.external_reference}</td>
                                        <td>{hold?.sb_inventory?.id}</td>
                                        <td>{hold?.notes}</td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle className="user-sell-page__action-button">
                                                    <img src={ActionIconDark}
                                                         alt="action icon"
                                                         className="action-icon dropdown-toggle"
                                                         draggable="false"
                                                    />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item className="user-sell-page__dropdown-item"
                                                                   onClick={() => unhold(hold)}>
                                                        Remove Hold
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className="user-sell-page__dropdown-item"
                                                                   onClick={() => editHold(hold)}>
                                                        Edit Hold
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className="user-sell-page__dropdown-item"
                                                                   onClick={() => handleCreateInvoiceClick(hold)}>
                                                        Create Invoice
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))
                            ) :
                            <tr>
                                <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>
                                    No results
                                </td>
                            </tr>
                        }
                        <AppModal centered={true} key={'holdModal'}
                                  show={showHold === selectedHold?.id}
                                  showHandle={handleHoldClose} title={'Edit Hold'} form={
                            <HoldForm
                                hold={selectedHold}
                                editMode={true}
                                event={selectedHold ? selectedHold!.sb_event : undefined}
                                inventory={selectedHold ? selectedHold.sb_inventory : undefined}
                                showHandle={handleHoldClose}
                            />
                        } onClose={() => {
                            pagination.paginate();
                        }}/>
                    </>
                </AppTable>
                :
                <AppLoader/>
            }
        </Container>
    )
}

export default HoldsPage;
