import {FormikState} from "formik";
import React, {FormEvent} from "react";

export const handleReset = async <T, P extends { paginate: (params?: any) => Promise<void> }>(
    resetForm: (nextState?: (Partial<FormikState<typeof filterInitial>> | undefined)) => void,
    filterInitial: T,
    pagination: P,
    setFilterValues: React.Dispatch<React.SetStateAction<T>>
) => {
    resetForm({values: filterInitial});
    pagination.paginate(filterInitial);
    setFilterValues(filterInitial);
};

export const handleOnFiltersChange = <T extends object, U extends keyof T>(
    event: FormEvent,
    filterValues: T,
    setFilterValues: React.Dispatch<React.SetStateAction<T>>
) => {
    const target = event.target as HTMLInputElement;

    (target.type === 'checkbox')
        ? filterValues[target.name as U] = target.checked as unknown as T[U]
        : filterValues[target.name as U] = target.value as unknown as T[U];

    setFilterValues(filterValues);
};

export const handleOnDatesChange = <T extends object, U extends keyof T>(
    date: T[U],
    name: U,
    filterValues: T,
    setFilterValues: React.Dispatch<React.SetStateAction<T>>
) => {
    filterValues[name] = date;
    setFilterValues(filterValues);
};
