import {BaseSyntheticEvent, useEffect, useState} from 'react';
import usePagination from "../helpers/UsePagination";
import {SbEvent} from "../models/Event";
import {sbEventsPaginateRequest} from "../@api/Event";
import {FormikValues} from "formik";
import {SbEventsFilters} from "../@interfaces/SbEventsFilters";
import {uniqBy} from "lodash";

function useHandleSbEventsPaginationScroll(ref: any, filterInitial: SbEventsFilters) {
    const pagination = usePagination<SbEvent>(sbEventsPaginateRequest);
    const [events, setEvents] = useState<SbEvent[] | undefined>(undefined);
    const [presavedEvents, setPresavedEvents] = useState<SbEvent[]>([]);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setPresavedEvents(uniqBy([...presavedEvents, ...pagination.items], (e) => e.id));
    }, [pagination.items]);

    useEffect(() => {
        pagination.setPerPage(100);
        pagination.paginate(filterInitial);
        setSelectedPage(pagination.currentPage + 1);
        pagination.setPerPage(50);
    }, []);

    useEffect(() => {
        setIsLoading(pagination.isLoading);

        if (!events?.length && presavedEvents?.length) {
            if (presavedEvents.length > 50) {
                setEvents(presavedEvents.slice(0, presavedEvents.length - 50));
            } else {
                setEvents(presavedEvents);
            }
        }
    }, [presavedEvents]);

    async function handleChange(e: BaseSyntheticEvent) {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

        if (bottom && (e.target.clientHeight !== 0)) {
            const neededPage = selectedPage + 1;

            setEvents(presavedEvents);
            setPresavedEvents(presavedEvents);
            setSelectedPage(selectedPage + 1);

            pagination.setCurrentPage(neededPage);
            await pagination.paginate(ref?.current!['values']);
        }
    }

    async function resetEvents() {
        setIsLoading(true);
        setPresavedEvents([]);
        setEvents(undefined);
        setSelectedPage(1);

        pagination.setCurrentPage(1);
        pagination.setPerPage(100);
        await pagination.paginate(filterInitial);
        pagination.setPerPage(50);

        setSelectedPage(2);
        ref?.current.setValues(filterInitial);
    }

    async function filterEvents(event: FormikValues) {
        setIsLoading(true);
        setEvents(undefined);
        setPresavedEvents([]);
        setSelectedPage(1);

        pagination.setCurrentPage(1);
        pagination.setPerPage(100);
        await pagination.paginate(event);
        pagination.setPerPage(50);

        setSelectedPage(2);
    }

    return {events, handleChange, resetEvents, filterEvents, isLoading};
}

export default useHandleSbEventsPaginationScroll;
