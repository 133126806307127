import React, {FC, useEffect, useState} from "react";
import {acMilanEventsPatch, sbEventsPaginateRequest} from "../../../@api/Event";
import {P1Event, P1EventSeatingPlansMapping, SbEvent} from '../../../models/Event';
import "./EditEventForm.scss";
import {HttpFailureResponse} from "../../../@api/Responses/HttpFailureResponse";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import usePagination from "../../../helpers/UsePagination";
import EventsComponent from "../EventsComponent/EventsComponent";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import EditEventP1SeatingPlan from './EditEventP1Categories/EditEventP1Categories';
import {
    deleteP1EventSeatingPlanMapping,
    getP1Event,
    getP1EventSeatingPlanMapping,
    getP1EventSeatingPlans,
    patchP1Events, saveP1EventSeatingPlanMapping,
    setP1EventSeatingPlanMappingUsage
} from '../../../@api/P1Event';
import AppLoader from '../../../ui/Loader/AppLoader/AppLoader';
import AppModal from "../../../ui/Modal/AppModal/AppModal";
import ConfirmationModal from "../../../ui/Modal/ConfirmationModal/ConfirmationModal";
import WarningIcon from "../../../assets/images/admin/warning.svg";
import {Tooltip, Whisper} from "rsuite";

interface EditFormProps {
    event_id: number;
    sb_event?: SbEvent;
    showHandle: () => void
    name: string;
    venue: string;
    city: string;
    date: string;
    integration_type?: string;
}

const EditEventForm: FC<EditFormProps> = (
    {
        event_id,
        showHandle,
        name,
        venue,
        city,
        date,
        sb_event,
        integration_type = 'p1'
    }
) => {
    const tableHeaders = [
        'ID',
        'Name',
        'Venue',
        'City',
        'Date',
        ''
    ];
    const sbEventFilterInitial = {
        id: '',
        name: '',
        venue: '',
        city: '',
        performer: '',
        mapped: '',
        from_date: new Date(),
        to_date: null,
        with_inventories: false,
    };
    const sbEventFormValues = [
        [
            {key: "id", value: "Search events by ID"},
            {key: "name", value: "Search events by name"},
            {key: "venue", value: "Search events by venue"},
            {key: "city", value: "Search events by city"}
        ]
    ];

    const [sbEventFilterValues, setSbEventFilterValues] = useState(sbEventFilterInitial);
    const [sbEventPage, setSbEventPage] = useState<number>(1);
    const [selectedEvent, setSelectedEvent] = useState<SbEvent | null>(sb_event ?? null);
    const [showP1EventSeatingPlanUsageChangeConfirmationDialog, setShowP1EventSeatingPlanUsageChangeConfirmationDialog] = useState<boolean>(false);
    const [showP1EventMappingChangeConfirmationDialog, setShowP1EventMappingChangeConfirmationDialog] = useState<boolean>(false);

    const [isUpdating, setIsUpdating] = useState<boolean>(true);
    const [useSeatingPlan, setUseSeatingPlan] = useState<boolean>(false);
    const [p1Event, setP1Event] = useState<P1Event>();
    const [p1SeatingPlan, setP1SeatingPlan] = useState<string[] | undefined>(undefined);
    const [p1EventSelectedSeatingPlan, setP1EventSelectedSeatingPlan] = useState<P1EventSeatingPlansMapping | undefined>(undefined);
    const [p1EventSeatingPlanMapping, setP1EventSeatingPlanMapping] = useState<P1EventSeatingPlansMapping[] | undefined>(undefined);
    const [p1EventSeatingPlanMappingsProcessed, setP1EventSeatingPlanMappingsProcessed] = useState<P1EventSeatingPlansMapping[] | undefined>(undefined);

    const pagination = usePagination<SbEvent>(sbEventsPaginateRequest);

    useEffect(() => {
        const modal = document.getElementsByClassName('modal-dialog')[0];
        modal.classList.add('map');
    }, []);

    // fetch P1Event data
    useEffect(() => {
        if (isUpdating) {
            // reset all states if updating
            setP1Event(undefined);
            setP1SeatingPlan(undefined);
            setP1EventSelectedSeatingPlan(undefined);
            setP1EventSeatingPlanMapping(undefined);
            setP1EventSeatingPlanMappingsProcessed(undefined);

            getP1Event(event_id)
                .then((res) => {
                    if (res instanceof HttpSuccessResponse) {
                        setP1Event(res.data);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [isUpdating, event_id]);

    // set Mapped categories to Event toggle value
    useEffect(() => {
        if (!isUpdating && p1Event) {
            setUseSeatingPlan(!!p1Event?.is_mapped_seating_plans);
        }
    }, [isUpdating, p1Event]);

    // fetch possible seating plan options for Mapped Categories
    useEffect(() => {
        const p1EventId = p1Event?.id;
        if (p1EventId) {
            getP1EventSeatingPlanMapping(p1EventId)
                .then((res) => {
                    if (res instanceof HttpSuccessResponse) {
                        setP1EventSeatingPlanMapping(res.data);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [p1Event?.id]);

    // fetch seating plan used for event
    useEffect(() => {
        const p1EventId = p1Event?.id;
        if (p1EventId && p1SeatingPlan === undefined) {
            getP1EventSeatingPlans(p1EventId)
                .then((res) => {
                    if (res instanceof HttpSuccessResponse) {
                        setP1SeatingPlan(Object.values(res.data));
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [p1Event?.id, p1SeatingPlan]);

    // merge possible seating plans options with used in event to same list
    useEffect(() => {
        if (p1SeatingPlan !== undefined && p1EventSeatingPlanMapping !== undefined) {
            const allSeatingPlans: P1EventSeatingPlansMapping[] = p1SeatingPlan.map(plan => {
                const mappedSeatingPlan = p1EventSeatingPlanMapping.find(p => p.seating_plan === plan);
                return {
                    ...mappedSeatingPlan,
                    seating_plan: plan
                }
            });
            setP1EventSeatingPlanMappingsProcessed(allSeatingPlans);
        }
    }, [p1SeatingPlan, p1EventSelectedSeatingPlan, p1EventSeatingPlanMapping]);

    // select default seating plan option if not selected
    useEffect(() => {
        if (useSeatingPlan && !!p1EventSeatingPlanMappingsProcessed?.length) {
            if (!p1EventSelectedSeatingPlan) {
                setP1EventSelectedSeatingPlan(p1EventSeatingPlanMappingsProcessed[0]);
            }
        }
    }, [useSeatingPlan, p1EventSeatingPlanMappingsProcessed, p1EventSelectedSeatingPlan]);

    // show selected seating plan details at the top of the table
    useEffect(() => {
        if (useSeatingPlan && p1EventSelectedSeatingPlan) {
            setSelectedEvent(p1EventSelectedSeatingPlan.sb_event ?? null);
        }
    }, [useSeatingPlan, p1EventSelectedSeatingPlan]);

    // turn off loading state, show form
    useEffect(() => {
        if (p1EventSeatingPlanMappingsProcessed !== undefined) {
            setIsUpdating(false);
        }
    }, [p1EventSeatingPlanMappingsProcessed])

    const mapDefaultResponseHandler = (res: HttpFailureResponse | HttpSuccessResponse) => {
        if (res instanceof HttpFailureResponse) {
            alert(res?.message);
            return;
        }

        if (!useSeatingPlan) {
            showHandle();
        } else {
            setIsUpdating(true);
        }

    };

    const edit = (id: number | null) => {
        if (isUpdating) {
            return;
        }

        const request = () => {
            switch (integration_type) {
                case 'p1':
                    if (useSeatingPlan) {
                        if (id && p1EventSelectedSeatingPlan) {
                            setIsUpdating(true);

                            return saveP1EventSeatingPlanMapping(event_id, {
                                sb_event_id: id,
                                seating_plan: p1EventSelectedSeatingPlan.seating_plan
                            });
                        } else if (p1EventSelectedSeatingPlan?.id) {
                            setIsUpdating(true);

                            return deleteP1EventSeatingPlanMapping(event_id, p1EventSelectedSeatingPlan.id);
                        }
                    }

                    return patchP1Events(event_id, {'sb_event_id': id});
                case 'ac_milan':
                    return acMilanEventsPatch(event_id, {'sb_event_id': id});
                default:
                    return null;
            }
        };

        request()?.then((res) => {
            mapDefaultResponseHandler(res);
        });
    };

    const handleSeatingPlanSelected = (plan: P1EventSeatingPlansMapping) => {
        setP1EventSelectedSeatingPlan(plan);
    }

    const handleSeatingPlanUsageChange = (newValue: boolean) => {
        const hasMultipleCategories = p1EventSeatingPlanMappingsProcessed && p1EventSeatingPlanMappingsProcessed.length;
        const hasMappedEvent = !!selectedEvent;

        if (!newValue && hasMultipleCategories) {
            const mappedEventSeatingPlans = p1EventSeatingPlanMappingsProcessed.filter(p => !!p.sb_event);
            if (mappedEventSeatingPlans && mappedEventSeatingPlans.length > 0) {
                return setShowP1EventSeatingPlanUsageChangeConfirmationDialog(true);
            }
        } else if (hasMappedEvent && newValue){
            return setShowP1EventSeatingPlanUsageChangeConfirmationDialog(true);
        }

        updateP1EventSeatingPlanUsage(newValue);
    }

    const updateP1EventSeatingPlanUsage = (newValue: boolean) => {
        setP1EventSeatingPlanMappingUsage(event_id, newValue).then((res) => {
            if (res instanceof HttpSuccessResponse) {
                setUseSeatingPlan(newValue);
                if (!newValue) {
                    setSelectedEvent(null);
                }
            }
        });
    }

    const handleP1EventSeatingPlanUsageConfirmationDialogClosed = (isConfirmed: boolean, newValue: boolean) => {
        if (isConfirmed) {
            updateP1EventSeatingPlanUsage(newValue);
        }
        setShowP1EventSeatingPlanUsageChangeConfirmationDialog(false);
    }

    const handleP1EventMappingRemoved = () => {
        setShowP1EventMappingChangeConfirmationDialog(true);
    }

    const handleP1EventMappingConfirmationDialogClosed = (isConfirmed: boolean) => {
        if (isConfirmed) {
            edit(null);
        }
        setShowP1EventMappingChangeConfirmationDialog(false);
    }

    const editEventP1SeatingPlan = (
        <EditEventP1SeatingPlan
            useSeatingPlan={useSeatingPlan}
            selectedSeatingPlan={p1EventSelectedSeatingPlan?.seating_plan}
            p1CategoriesSeatingPlanMap={p1EventSeatingPlanMappingsProcessed ?? []}
            handleSeatingPlanUsageChange={handleSeatingPlanUsageChange}
            seatingPlanSelected={handleSeatingPlanSelected}
            isUpdating={isUpdating || !p1EventSeatingPlanMappingsProcessed}
        />
    );

    const pleaseUnmapEventTooltip = (
        <Tooltip>
            Please unmap selected Event first.
        </Tooltip>
    );

    return (
        <div className="edit-event">
            <div className={"edit-event-title"}>
                <h5>{name},</h5>
                <h5>{venue}, {city},</h5>
                <h5>{date}</h5>
            </div>
            <EventsComponent
                pagination={pagination}
                filterInitial={sbEventFilterInitial}
                formValues={sbEventFormValues}
                filterValues={sbEventFilterValues}
                setFilterValues={setSbEventFilterValues}
                selectedPage={sbEventPage}
                setSelectedPage={setSbEventPage}
                tableComponent={
                    <AppTable
                        columns={tableHeaders}
                        isLoading={pagination.isLoading}
                        pagination={{
                            lastPage: pagination.lastPage,
                            currentPage: pagination.currentPage,
                            total_items: pagination.totalItems
                        }}
                        onPageChange={(page, perPage) => {
                            pagination.setCurrentPage(page);
                            pagination.setPerPage(perPage);
                            pagination.paginate(sbEventFilterValues);
                        }}
                        withAutoHeight={false}
                    >
                        {!p1Event ? (<tr><td colSpan={6}><AppLoader fullHeight={true} /></td></tr>) : (
                            <>
                                {selectedEvent && !isUpdating
                                    ? <tr className="edit-event__selected">
                                        <td>
                                            <div className={"sb-events-page__td-id"}>
                                                {selectedEvent.event_id}
                                            </div>
                                        </td>
                                        <td>{selectedEvent.name}</td>
                                        <td>{selectedEvent.venue}</td>
                                        <td>{selectedEvent.city}</td>
                                        <td>{dateToLocaleString(selectedEvent.occurs_at)}</td>
                                        <td>
                                            <button
                                                className="auth-wrapper__submit-btn my-2 px-2 edit-event__white_button"
                                                type="button"
                                                onClick={handleP1EventMappingRemoved}
                                                disabled={isUpdating}
                                            >
                                                Unmap & Save
                                            </button>
                                        </td>
                                    </tr>
                                    : <></>
                                }
                                {pagination.items.length
                                    ? pagination.items?.map((event, index) => {
                                        const mapAndSaveButton = (
                                            <button
                                                className="auth-wrapper__submit-btn px-2"
                                                type="button"
                                                disabled={isUpdating || !!selectedEvent}
                                                onClick={() => edit(event.id)}
                                            >
                                                Map & Save
                                            </button>
                                        );

                                        return (
                                            <React.Fragment key={'SbEvents' + index}>
                                                {!(selectedEvent?.event_id === event.event_id) && (
                                                    <tr data-row={index}>
                                                        <td>
                                                            <div className={"sb-events-page__td-id"}>
                                                                {event.event_id}
                                                            </div>
                                                        </td>
                                                        <td>{event.name}</td>
                                                        <td>{event.venue}</td>
                                                        <td>{event.city}</td>
                                                        <td>{dateToLocaleString(event.occurs_at)}</td>
                                                        <td>
                                                            {!!selectedEvent ? (
                                                                <Whisper placement="left" controlId="control-id-hover" trigger="hover" speaker={pleaseUnmapEventTooltip}>
                                                                    {mapAndSaveButton}
                                                                </Whisper>
                                                            ) : mapAndSaveButton}
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        )
                                    }) : (
                                        <tr className={"events-page__table__tr"}>
                                            <td className={"text-center"} colSpan={8}>No events</td>
                                        </tr>
                                    )
                                }
                            </>
                        )}
                    </AppTable>
                }
                customFilters={() => <></>}
                bottomFilterPanel={editEventP1SeatingPlan}
            />
            <AppModal
                headerStyle={{display: "none"}}
                title={''}
                centered={true}
                closeButton={false}
                form={
                    <ConfirmationModal onClose={(isConfirmed) => handleP1EventSeatingPlanUsageConfirmationDialogClosed(isConfirmed, !useSeatingPlan)}>
                        <div className={'d-flex justify-content-center mb-3'}>
                            <img src={WarningIcon} alt={'warning'} style={{height: '50px', width: '50px'}} />
                        </div>
                        <p>Switching this toggle will delete {useSeatingPlan ? 'existing category mappings and ' : ''}all related Skybox listings. Are you sure you want to proceed?</p>
                    </ConfirmationModal>
                }
                show={showP1EventSeatingPlanUsageChangeConfirmationDialog}
                showHandle={() => setShowP1EventSeatingPlanUsageChangeConfirmationDialog(false)}
                onClose={() => setShowP1EventSeatingPlanUsageChangeConfirmationDialog(false)}
            />
            <AppModal
                headerStyle={{display: "none"}}
                title={''}
                centered={true}
                closeButton={false}
                form={
                    <ConfirmationModal onClose={(isConfirmed) => handleP1EventMappingConfirmationDialogClosed(isConfirmed)}>
                        <div className={'d-flex justify-content-center mb-3'}>
                            <img src={WarningIcon} alt={'warning'} style={{height: '50px', width: '50px'}} />
                        </div>
                        <p>Unmapping this Event will delete all related Skybox listings. Are you sure you want to proceed?</p>
                    </ConfirmationModal>
                }
                show={showP1EventMappingChangeConfirmationDialog}
                showHandle={() => setShowP1EventMappingChangeConfirmationDialog(false)}
                onClose={() => setShowP1EventMappingChangeConfirmationDialog(false)}
            />
        </div>
    )
}

export default EditEventForm;
