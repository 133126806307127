import {configureStore} from '@reduxjs/toolkit';
import authReducer from './Reducers/Auth';
import eventReducer from './Reducers/Event';
import inventoryReducer from './Reducers/Inventory';
import filterReducer from './Reducers/Filters';
import currencyReducer from './Reducers/Currency';
import purchaseOrderReducer from './Reducers/PurchaseOrder';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        event: eventReducer,
        inventory: inventoryReducer,
        filter: filterReducer,
        currency: currencyReducer,
        purchaseOrder: purchaseOrderReducer
    },
});

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
