import "./AppDatePicker.scss";
import React, {FC} from "react";
import FormikDateInput from "../../../components/Inputs/FormikDateInput/FormikDateInput";

interface Props {
    name: string,
    placeholder: string,
    shouldShowTime?: boolean
    customClass?: string
    monthsShown?: number
    showTimeSelectOnly?: boolean
    timeIntervals?: number
    timeCaption?: string
    dateFormat?: string
    sendDate?: (a: any) => any
}

const AppDatePicker: FC<Props> = (
    {
        name,
        placeholder,
        shouldShowTime,
        customClass='',
        monthsShown = 1,
        showTimeSelectOnly= false,
        timeCaption='Time',
        timeIntervals,
        dateFormat,
        sendDate
    }) => {
    return (
        <div className="app-date-picker">
            <FormikDateInput
                name={name}
                placeholderText={placeholder}
                autoComplete='off'
                class={customClass ? customClass : 'form-control'}
                showTimeSelect={shouldShowTime}
                monthsShown={monthsShown}
                showTimeSelectOnly={showTimeSelectOnly}
                timeCaption={timeCaption}
                timeIntervals={timeIntervals}
                dateFormat={dateFormat}
                sendDate={(date: any) => sendDate ? sendDate(date) : () => null}
            />
        </div>
    )
}

export default AppDatePicker;
