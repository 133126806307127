import {useState} from 'react';
import {HttpSuccessResponse} from '../@api/Responses/HttpSuccessResponse';
import {GlobalStatusCodesEnum} from '../enums/ResponseStatuses/GlobalStatusCodesEnum';
import {HttpFailureResponse} from '../@api/Responses/HttpFailureResponse';
import {PaginationResponseData} from '../@interfaces/Response/PaginationResponseData';
import {PaginationParams} from '../@interfaces/PaginationParams';

const usePagination = <T>(
  loader: (params: PaginationParams<T>) => Promise<HttpSuccessResponse<GlobalStatusCodesEnum, PaginationResponseData<T>> | HttpFailureResponse<GlobalStatusCodesEnum, any>>,
) => {
  const paginationParams: {page: number, perPage: number} = {
    page: 1,
    perPage: 50,
  };

  const [items, setItems] = useState<T[]>([]);
  const [currentPage, _setCurrentPage] = useState(paginationParams.page);
  const [totalItems, setTotalItems] = useState(0);
  const [perPage, _setPerPage] = useState(paginationParams.perPage);
  const [lastPage, setLastPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const setCurrentPage = (v: number) => {
    _setCurrentPage(v);
    paginationParams.page = v;
  };

  const setPerPage = (v: number) => {
    _setPerPage(v);
    paginationParams.perPage = v;
  };

  const successResponse = (res: HttpSuccessResponse<GlobalStatusCodesEnum, PaginationResponseData<T>>) => {
    setItems(res.data.items);
    setCurrentPage(res.data.current_page);
    setTotalItems(res.data.total_items);
    setLastPage(res.data.last_page);
  };

  const failureResponse = (res: HttpFailureResponse<GlobalStatusCodesEnum, any>) => {
    setItems([]);
    setCurrentPage(1);
    setTotalItems(0);
    setLastPage(0);
    setError(res.message);
  };

  const paginate = async (params: any = {}) => {
    setError('');
    setIsLoading(true);

    const res = await loader({...paginationParams, ...params});

    if (res instanceof HttpSuccessResponse) {
      successResponse(res);
    } else {
      failureResponse(res);
    }

    setIsLoading(false);
  };

  return {
    paginate,
    items,
    setItems,
    currentPage,
    setCurrentPage,
    totalItems,
    setTotalItems,
    lastPage,
    perPage,
    setPerPage,
    isLoading,
    error,
  };
};

export default usePagination;
