import {Inventory} from "../models/Inventory";
import {LEV_VENDOR_ID} from "../constants/Constants";
import {InventoryConsignmentEnum} from "../enums/InventoryConsignmentEnum";
import {StockTypes} from "../enums/Skybox/StockTypes";
import { convert } from './CurrencyConverter';
import { Currency } from '../models/Currency';
import {User} from "../models/User";

export const getInventoryAmount = (inventory: Inventory) => {
    const isP1Owner = inventory.p1_inventory?.id  && compareConsignmentStatus(inventory, InventoryConsignmentEnum.Yes);
    const noConsignmentCondition = inventory.consignment && compareConsignmentStatus(inventory, InventoryConsignmentEnum.No);

    return ((inventory.sb_vendor_id === LEV_VENDOR_ID) || isP1Owner || noConsignmentCondition) ? inventory.list_price : inventory.unit_amount;
};

export const getInventoryAmountInCurrency = (inventory: Inventory, toCurrency: string, currencies: Currency[]) => {
    const amount = getInventoryAmount(inventory);

    return !currencies.length ? amount : convert(inventory.currency_symbol, toCurrency, amount, currencies);
};

export const compareConsignmentStatus = (inventory: Inventory, inventoryConsignmentEnum: InventoryConsignmentEnum) => {
    const inventoryConsignment = inventory.consignment === null ? InventoryConsignmentEnum.No : inventory.consignment

    return inventoryConsignment === inventoryConsignmentEnum;
};

export const availabilityOfUpload = (
    setIsElectronicTicket: (value: React.SetStateAction<boolean>) => void,
    chosenStockType: string = '',
    quantity: string = '',
    lowSeat: string = '',
    isCopy: boolean = false
) => {
    const section = (document.getElementsByName('section')[isCopy ? 1 : 0] as HTMLInputElement)?.value;
    const row = (document.getElementsByName('row')[isCopy ? 1 : 0] as HTMLInputElement)?.value;
    const indexOfE = Object.values(StockTypes).indexOf('E-ticket' as unknown as StockTypes);
    const indexOfM = Object.values(StockTypes).indexOf('Mobile QR' as unknown as StockTypes);

    if (!chosenStockType && !quantity && !lowSeat) {
        chosenStockType = (document.getElementsByName('stock_type')[0] as HTMLSelectElement)?.value;
        quantity = (document.getElementsByName('quantity')[0] as HTMLInputElement)?.value;
        lowSeat = (document.getElementsByName('low_seat')[0] as HTMLInputElement)?.value;
    }

    const isUploadPdfDisabled =
        !(chosenStockType === Object.keys(StockTypes)[indexOfE] || chosenStockType === Object.keys(StockTypes)[indexOfM]) ||
        (!(quantity) || !(section) || !(row) || !(lowSeat));

    setIsElectronicTicket(isUploadPdfDisabled);
};

export const userHasIntegrations = (user: User | undefined): boolean => {
    if (!user || !user?.active_integrations) {
        return false;
    }

    const hasTicomboKey = !!user.api_keys?.ticombo;

    return user.active_integrations.length > 0 && hasTicomboKey;
}
