import React, {FC, useEffect, useState} from "react";
import usePagination from "../../../helpers/UsePagination";
import {Container} from "react-bootstrap";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import {approveTransfer, getPaginatedTransferredInventories} from "../../../@api/Inventory";
import {Inventory} from "../../../models/Inventory";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import {StockTypes} from "../../../enums/Skybox/StockTypes";
import AppButton from "../../../ui/Buttons/AppButton/AppButton";
import AppLoader from "../../../ui/Loader/AppLoader/AppLoader";

const TransfersPage: FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const tableHeaders = [
        'Event name',
        'Event venue',
        'Event date',
        'QTY',
        'Section',
        'Row',
        'Unit price',
        'Public notes',
        'Seat type',
        'Stock type',
        'In-hand date',
        'Approve transfer'
    ];

    const pagination = usePagination<Inventory>(getPaginatedTransferredInventories);

    useEffect(() => {
        pagination.paginate();
    }, []);

    const handleApprove = async (inventory: Inventory) => {
        setIsLoading(true);

        await approveTransfer(inventory.id);

        setIsLoading(false);

        pagination.paginate();
    }

    return (
        <Container className={'admin-users-page events'}>
            <div className={'w-100 mt-3'}>
                <h1 className={'admin-users-page__title'}>Transfers</h1>
                {!pagination.isLoading ?
                    <AppTable
                        columns={tableHeaders}
                        pagination={{
                            lastPage: pagination.lastPage,
                            currentPage: pagination.currentPage,
                            total_items: pagination.totalItems
                        }}
                        onPageChange={(page, perPage) => {
                            pagination.setCurrentPage(page);
                            pagination.setPerPage(perPage);
                            pagination.paginate();
                        }}>
                        <>
                            {pagination.items.length ? (
                                    pagination.items?.map((inventory, index) => (
                                        <tr data-row={index} key={inventory.id + index}>
                                            <td className="event-name-td"
                                                title={inventory.sb_event?.name}>{inventory.sb_event?.name}</td>
                                            <td>{inventory.sb_event?.venue}</td>
                                            <td>{dateToLocaleString(inventory.sb_event?.occurs_at)}</td>
                                            <td>{inventory.quantity}</td>
                                            <td>{inventory.section}</td>
                                            <td>{inventory.row}</td>
                                            <td>{inventory.unit_amount}</td>
                                            <td>{inventory.public_notes || '-'}</td>
                                            <td>{inventory.seat_type}</td>
                                            <td>{StockTypes[inventory.stock_type as keyof typeof StockTypes]}</td>
                                            <td>{dateToLocaleString(inventory.in_hand_date)}</td>
                                            <td>
                                                <div className={'customers_actions'}>
                                                    <div onClick={() => handleApprove(inventory)}>
                                                        <AppButton disabled={isLoading} text={'Approve'}/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) :
                                <tr>
                                    <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>
                                        No results
                                    </td>
                                </tr>
                            }
                        </>
                    </AppTable>
                    :
                    <AppLoader/>
                }
            </div>
        </Container>
    )
}


export default TransfersPage;
