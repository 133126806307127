import ReactPaginate from "react-paginate";
import React, {FC, useState} from "react";
import {Dropdown} from "react-bootstrap";
import "./AppPagination.scss";

interface Props {
    pagination: { lastPage: number, currentPage: number, total_items: number },
    onPageChange?: (page: number, perPage: number) => void,
}

const AppPagination: FC<Props> = ({pagination, onPageChange}) => {
    const limitOptions = [10, 20, 50, 100, 250, 500];

    const [selectedLimit, setSelectedLimit] = useState<number>(50);

    const handlePageChange = (s: { selected: number }) => {
        const newPage = s.selected + 1;

        if (newPage === pagination.currentPage) return;

        if (onPageChange) {
            onPageChange(newPage, selectedLimit);
        }
    };

    const handleLimitChange = (limit: number) => {
        setSelectedLimit(limit);

        if (onPageChange) {
            onPageChange(1, limit);
        }
    };

    if (!(pagination.lastPage && pagination.currentPage && pagination.total_items)) {
        return null;
    }

    return (
        <div className="app-pagination">
            <div className="app-pagination__limit_wrapper">
                <Dropdown className="app-pagination__limit">
                    <Dropdown.Toggle>{selectedLimit}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {limitOptions.map((item) => (
                            <Dropdown.Item
                                key={item}
                                onClick={() => handleLimitChange(item)}
                                className="user-sell-page__dropdown-item"
                            >
                                {item}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                Items per page
            </div>

            <ReactPaginate
                className="app-pagination__pages"
                pageCount={pagination.lastPage}
                forcePage={pagination.currentPage - 1}
                onPageChange={handlePageChange}
                nextClassName="d-none"
                previousClassName="d-none"
            />

            <div className="app-pagination__info">
                Showing {(pagination.currentPage - 1) * Number(selectedLimit) + 1} - {(pagination.total_items < Number(selectedLimit) * pagination.currentPage) ? pagination.total_items : Number(selectedLimit) * pagination.currentPage} of {pagination.total_items}
            </div>
        </div>
    )
};

export default AppPagination;
