import {Container} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import AppTable from '../../../ui/Table/AppTable/AppTable';
import usePagination from '../../../helpers/UsePagination';
import {Inventory} from '../../../models/Inventory';
import {getSoldInventories} from '../../../@api/Inventory';
import {dateToLocaleString} from '../../../helpers/DateFormatter';
import {StockTypes} from '../../../enums/Skybox/StockTypes';
import {Field, Form, Formik} from 'formik';
import DefaultFormikInput from '../../../components/Inputs/DefaultFormikInput/DefaultFormikInput';
import FormikDateInput from '../../../components/Inputs/FormikDateInput/FormikDateInput';
import {performersGetCategoriesRequest} from '../../../@api/Performer';
import {HttpSuccessResponse} from '../../../@api/Responses/HttpSuccessResponse';
import {handleOnDatesChange, handleOnFiltersChange, handleReset} from '../../../helpers/Filters';
import AppCheckbox from '../../../ui/Inputs/AppCheckbox/AppCheckbox';
import './SoldInventoryPage.scss';
import {getInventoryAmount} from "../../../helpers/InventoryHelper";
import {getCurrencySymbol} from "../../../helpers/CurrencyConverter";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import CheckIcon from "../../../assets/images/admin/check-icon.png";
import {PaymentMethods} from "../../../enums/PaymentMethods";
import AppLoader from "../../../ui/Loader/AppLoader/AppLoader";

interface FilterProps {
    event_name: string;
    event_category: string;
    event_date_from: string;
    event_date_to: string;
    invoice_date_from: string;
    invoice_date_to: string;
    local_inventories: boolean;
}

const SoldInventoryPage = () => {
    const tableHeaders = [
        'Invoice ID',
        'Invoice Date',
        'Created by',
        'External Ref.',
        'Customer',
        'Purchase ID',
        'Vendor',
        'Event Type',
        'Category',
        'Performer',
        'Event Name',
        'Event ID',
        'Event Date',
        'Venue',
        'Section',
        'Row',
        'Seats',
        'Qty',
        'Stock Type',
        'In Hand Date',
        'Internal Notes',
        'Unit Cost',
        'Total Cost',
        'Total',
        'Payment Method',
        'Fulfillment Status',
        'Received',
        'Inventory tags',
        'PDF/Barcodes'
    ];

    const filterInitial: FilterProps = {
        event_name: '',
        event_category: '',
        event_date_from: '',
        event_date_to: '',
        invoice_date_from: '',
        invoice_date_to: '',
        local_inventories: true,
    };

    const dateFilters = [
        {name: 'event_date_from', placeholder: 'Event Date From'},
        {name: 'event_date_to', placeholder: 'Event Date To'},
        {name: 'invoice_date_from', placeholder: 'Invoice Date From'},
        {name: 'invoice_date_to', placeholder: 'Invoice Date To'},
    ];

    const [categories, setCategories] = useState<string[]>([]);
    const [filterValues, setFilterValues] = useState<FilterProps>(filterInitial);

    const pagination = usePagination<Inventory>(getSoldInventories);
    const currencies = useSelector((state: RootState) => state.currency.currencies);

    useEffect(() => {
        performersGetCategoriesRequest()
            .then((res) => {
                if (res instanceof HttpSuccessResponse) {
                    setCategories(
                        Object.values(res.data)
                            .flat()
                            .filter((category) => category.category_name)
                            .map((category) => category.category_name!),
                    );
                }
            });

        pagination.paginate(filterInitial);
    }, []);

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title mb-2'}>Sold Inventory</h1>
            {!pagination.isLoading ?
                <div className="mb-3 sold-inventory-table">
                    <Formik
                        initialValues={filterInitial}
                        onSubmit={async (values) => {
                            pagination.setPerPage(pagination.perPage);
                            await pagination.paginate(values);
                        }}
                    >
                        {({resetForm}) => {
                            return (
                                <Form className="table__filters"
                                      onChange={(event) => handleOnFiltersChange(event, filterValues, setFilterValues)}>
                                    <div className="table__filters__wrapper">
                                        {dateFilters.map((data, index) => (
                                            <FormikDateInput
                                                key={index + 'Dates'}
                                                name={data.name}
                                                placeholderText={data.placeholder}
                                                showTimeSelect
                                                autoComplete={'off'}
                                                sendDate={(date: string, name: string) =>
                                                    handleOnDatesChange(date, name as keyof FilterProps, filterValues, setFilterValues)
                                                }
                                            />
                                        ))}
                                        <Field name={'event_category'} as="select">
                                            <option key={'eventCategory'} value="">Search by category</option>
                                            {
                                                categories.map(categoryOption => (
                                                    <option key={categoryOption + 'EventCategory'}
                                                            value={categoryOption}>{categoryOption}</option>
                                                ))
                                            }
                                        </Field>
                                    </div>
                                    <div className="table__filters__wrapper">
                                        <DefaultFormikInput
                                            name={'event_name'}
                                            placeholder={'Event Name'}
                                            autocomplete={'off'}
                                            class={'mb-2'}
                                        />
                                        <AppCheckbox
                                            label={'Local Inventories'}
                                            id={'local_inventories'}
                                            name={'local_inventories'}
                                            value={true}
                                        />
                                        <button type="reset"
                                                className="filters-button-reset"
                                                onClick={async () => {
                                                    await handleReset(resetForm, filterInitial, pagination, setFilterValues);
                                                }}
                                        >
                                            Reset
                                        </button>
                                        <button type="submit" className="filters-button-apply">
                                            Apply Filters
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
                :
                null
            }

            {!pagination.isLoading ?
                <AppTable
                    columns={tableHeaders}
                    pagination={{
                        lastPage: pagination.lastPage,
                        currentPage: pagination.currentPage,
                        total_items: pagination.totalItems
                    }}
                    onPageChange={(page, perPage) => {
                        pagination.setCurrentPage(page);
                        pagination.setPerPage(perPage);
                        pagination.paginate(filterValues);
                    }}
                >
                    <>
                        {pagination.items.length ?
                            pagination.items.map((inventory, index) => (
                                <tr key={index} data-row={index}>
                                    <td>{inventory.invoice_id}</td>
                                    <td>{dateToLocaleString(inventory.invoice?.date) || '-'}</td>
                                    <td>{inventory.invoice?.owner?.name || ''}</td>
                                    <td>{inventory.invoice?.external_reference}</td>
                                    <td>{inventory.invoice?.customers ? inventory.invoice?.customers?.email : inventory.invoice?.customer_email}</td>
                                    <td>{inventory.purchase_order_id}</td>
                                    <td>{inventory.purchase_orders?.user_vendor?.display_name}</td>
                                    <td>{inventory.sb_event?.sb_performer?.category_type ?? ''}</td>
                                    <td>{inventory.sb_event?.sb_performer?.category_name ?? ''}</td>
                                    <td>{inventory.sb_event?.sb_performer?.name}</td>
                                    <td>{inventory.sb_event?.name}</td>
                                    <td>{inventory.sb_event?.event_id}</td>
                                    <td>{dateToLocaleString(inventory.sb_event?.occurs_at)}</td>
                                    <td>{inventory.sb_event?.venue}</td>
                                    <td>{inventory.section}</td>
                                    <td>{inventory.row}</td>
                                    <td>{inventory.low_seat} - {inventory.high_seat}</td>
                                    <td>{inventory.quantity}</td>
                                    <td>{StockTypes[inventory.stock_type as keyof typeof StockTypes]}</td>
                                    <td>{inventory.in_hand_date}</td>
                                    <td>{inventory.internal_notes || ''}</td>
                                    <td>{getCurrencySymbol(inventory.currency_symbol, currencies)}{getInventoryAmount(inventory)}</td>
                                    <td>{getCurrencySymbol(inventory.currency_symbol, currencies)}{(inventory.quantity * getInventoryAmount(inventory)).toFixed(2)}</td>
                                    <td>{getCurrencySymbol(inventory.invoice?.currency_code, currencies)}{(inventory?.invoice?.amount * inventory.quantity).toFixed(2)}</td>
                                    <td>{PaymentMethods[inventory.invoice?.payment_method as keyof typeof PaymentMethods] || ''}</td>
                                    <td>{inventory.invoice?.fulfillment_status || ''}</td>
                                    <td>{inventory.invoice?.received || ''}</td>
                                    <td>{inventory.tags || ''}</td>
                                    <td>{inventory.sb_data?.filesUploaded ?
                                        <img src={CheckIcon}
                                             alt="action icon"
                                             draggable="false"
                                        />
                                        :
                                        ''}
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>No
                                    results
                                </td>
                            </tr>
                        }
                    </>
                </AppTable>
                :
                <AppLoader/>
            }
        </Container>
    );
};

export default SoldInventoryPage;
