import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import './TableEventBlock.scss';
import { useSelector } from 'react-redux';
import Cogwheel from '../../../../../assets/images/other/cogwheel.svg';
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik, FormikValues } from 'formik';
import { Inventory } from '../../../../../models/Inventory';
import DuplicateForm from '../../../../../components/SellPage/DuplicateForm/DuplicateForm';
import DefaultFormikInput from '../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput';
import { cancelInventory, mergeInventory } from '../../../../../@api/Inventory';
import AppModal from '../../../../../ui/Modal/AppModal/AppModal';
import { RootState } from '../../../../../store/Store';
import SplitForm from '../../../../../components/SellPage/SplitForm/SplitForm';
import { HttpFailureResponse } from '../../../../../@api/Responses/HttpFailureResponse';
import { editTicketRequest } from '../../../../../@api/User';
import { TicketData } from '../../EditTicketPage/Data';
import BuyTicketForm from '../../../BuyPage/components/BuyTicketForm/BuyTicketForm';
import HoldForm from '../../../../../components/SellPage/HoldForm/HoldForm';
import { SbEvent } from '../../../../../models/Event';
import { QuoteBuilderInventory } from '../../../../../@interfaces/QuoteBuilderData';
import QuoteBuilder from '../../../BuyPage/components/QuoteBuilder/QuoteBuilder';
import { StockTypes } from '../../../../../enums/Skybox/StockTypes';
import { SplitTypes } from '../../../../../enums/Skybox/SplitTypes';
import { convert, getCurrencySymbol } from '../../../../../helpers/CurrencyConverter';
import { HttpSuccessResponse } from '../../../../../@api/Responses/HttpSuccessResponse';
import { InventoryStatusTypes } from '../../../../../enums/Skybox/InventoryStatusTypes';
import { getInventoryAmount, userHasIntegrations } from '../../../../../helpers/InventoryHelper';
import { RolesEnum } from '../../../../../enums/RolesEnum';
import { removeHold } from '../../../../../@api/Holds';
import BidForm from '../../../../../components/SellPage/BidForm/BidForm';
import { EventIntegrations } from './EventIntegrations/EventIntegrations';
import AppButton from '../../../../../ui/Buttons/AppButton/AppButton';
import { Message, useToaster } from 'rsuite';
import { IntegrationsEnum } from '../../../../../enums/IntegrationsEnum';

interface Props {
    event: SbEvent,
    inventories: Inventory[],
    getData: () => void,
    actions: string,
    selectedCurrency: string,
    handleSuccessBuy?: (event?: SbEvent, inventory?: Inventory) => void
}

const TableEventBlock: FC<Props> = ({event, inventories, getData, actions, selectedCurrency, handleSuccessBuy}) => {
    const loggedUser = useSelector((state: RootState) => state.auth.user);
    const currencies = useSelector((state: RootState) => state.currency.currencies);
    const [isEditMode, setIsEditMode] = useState<boolean | undefined>(undefined);
    const navigate = useNavigate();
    const toaster = useToaster();

    useEffect(() => {
        convertInventories();
    }, [selectedCurrency, inventories])

    const isActionChange = useMemo(() => {
        return actions === 'change'
    }, [actions]);
    const [bidModalId, setBidModalId] = useState<number | null>(null);
    const [quoteModalOpen, setQuoteModalOpen] = useState(false);
    const [inventoryChecked, setInventoryChecked] = useState<{ [id: number]: boolean }>({});
    const [selectedInventoryData, setSelectedInventoryData] = useState<QuoteBuilderInventory[]>([]);

    const {
        section,
        fromDate,
        toDate,
        name,
        inventory_id,
        row,
        city,
        venue,
        performer
    } = useSelector((state: RootState) => state.filter);
    const [showSplit, setShowSplit] = useState<number | null>(null);
    const [showCreateInvoice, setShowCreateInvoice] = useState<number | null>(null);
    const [showDuplicate, setShowDuplicate] = useState<number | null>(null);
    const [buyModalOpen, setBuyModalOpen] = useState<number | null>(null);
    const [selectedInventory, setSelectedInventory] = useState<number>(0)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showHold, setShowHold] = useState<number | null>(null);
    const [convertedAmounts, setConvertedAmounts] = useState<{ id: number; convertedAmount: number }[]>([]);

    const [loading, setLoading] = useState<string[]>([]);

    const hasIntegrations = userHasIntegrations(loggedUser);

    const setEventData = (event: any) => {
        return `${event.name} | ${event.venue} - ${(new Date(event.occurs_at)).toDateString()} ${(new Date(event.occurs_at)).toLocaleTimeString()}`;
    };

    const handleCreateInvoiceClick = (inventory: Inventory) => {
        navigate(`/user/invoices/new?inventoryId=${inventory.id}`, {
            state: {inventoryData: inventory}
        });
    };

    const split = async (id: number) => {
        setShowSplit(id);
    };

    const handleSplitClose = () => {
        setShowSplit(null);
        getData();
    };

    const handleBidClose = () => {
        setBidModalId(null);
        getData();
    };

    const duplicate = (id: number) => {
        setShowDuplicate(id);
    };

    const handleDuplicateClose = () => {
        setShowDuplicate(null);
        getData();
    };

    const handleBuyTicketClose = (event?: SbEvent, inventory?: Inventory) => {
        setBuyModalOpen(null);

        if (handleSuccessBuy && inventory && event) {
            handleSuccessBuy(event, inventory);
        }
    };

    const handleQuoteModalClose = () => {
        setQuoteModalOpen(false);
        setSelectedInventoryData([]);
    };

    const convertInventories = () => {
        const convertedAmounts: { id: number; convertedAmount: number }[] = inventories.map((inventory) => {
            const amountToConvert = getInventoryAmount(inventory);
            const convertedAmount = Math.ceil(convert(inventory.currency_symbol, selectedCurrency, amountToConvert, currencies));

            return {id: inventory.id, convertedAmount};
        });

        setConvertedAmounts(convertedAmounts);
    };

    const openQuoteModal = () => {
        const selectedInventoryIds = Object.keys(inventoryChecked).map(Number);
        const tempInventories: QuoteBuilderInventory[] = [];

        inventories.forEach((item) => {
            if (selectedInventoryIds.includes(item.id)) {
                tempInventories.push({
                    id: item.id,
                    inventory_id: item.inventory_id,
                    quantity: (item.shown_quantity < item.quantity && item.shown_quantity)
                        ? item.shown_quantity
                        : item.quantity,
                    quantity_options: addQuantityOptions(item),
                    section: item.section,
                    row: item.row,
                    stock_type: StockTypes[item.stock_type as keyof typeof StockTypes],
                    split_type: item.split_type,
                    custom_split: item.custom_split,
                    public_notes: item.public_notes,
                    old_price: Number(item.unit_amount),
                    new_price: Number(item.unit_amount),
                    old_currency: item.currency_symbol,
                    new_currency: item.currency_symbol,
                    commission: 0,
                    commission_type: 'unit',
                    sb_event: item.sb_event,
                });
            }
        });

        setSelectedInventoryData(tempInventories);
        setQuoteModalOpen(true);
    };

    const addQuantityOptions = (inventory: Inventory) => {
        let tempOptions: { name: string, value: string }[] = [{name: '', value: ''}];

        for (let i = 1; i <= inventory.quantity; i++) {
            if (inventory.split_type === SplitTypes.Custom) {
                if (inventory.custom_split && inventory.custom_split[i]) {
                    tempOptions.push({name: String(i), value: String(i)});
                }
            } else {
                tempOptions.push({name: String(i), value: String(i)});
            }
        }

        if ((inventory.split_type === SplitTypes.NeverLeaveOne && inventory.quantity > 1))
            tempOptions.splice(tempOptions.length - 2, 1);

        return tempOptions;
    };

    const openBuyTicketModal = (inventory: Inventory) => {
        setSelectedInventory(inventory.id);
        setBuyModalOpen(inventory.id);
    };

    const createInvoice = async (id: number) => {
        setShowCreateInvoice(id)
    };

    const cancel = async (id: number) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure?')) {
            const res = await cancelInventory(id);

            if (res instanceof HttpFailureResponse) {
                alert(res?.message);
            }
        }
        getData();
    };

    const handleSellClose = () => {
        setShowCreateInvoice(null);
        getData();
    };

    const unhold = async (id: number) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure you want to remove hold?')) {
            const res = await removeHold(id);

            if (res instanceof HttpSuccessResponse) {
                getData();
                alert('Hold removed!');
            }
        }
    };

    const bid = async (id: number) => {
        setBidModalId(id);
    };

    const handleHoldClose = () => {
        setShowHold(null);
        getData();
    };

    const createHold = (id: number) => {
        setShowHold(id);
        setIsEditMode(false);
    };

    const editHold = async (id: number) => {
        setShowHold(id);
        setIsEditMode(true);
    }

    const merge = async () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Confirm merge selected inventories?')) {
            const res = await mergeInventory(Object.keys(inventoryChecked));

            getData();
            alert(res?.message);

            if (res?.status) {
                setInventoryChecked({});
            }
        }
    };

    const onChangeValues = async (values: FormikValues, inventory: Inventory, field: string) => {
        setLoading([...loading, `${field}-${inventory.id}`]);
        const data = {
            ...values
        } as TicketData;

        const res = await editTicketRequest(Number(inventory.id), data);

        setLoading([...loading.filter(a => a !== `${field}-${inventory.id}`)]);

        const type = res instanceof HttpSuccessResponse ? 'success' : 'error';
        const message = (
          <Message showIcon type={type} closable>
              <strong>{type}!</strong> {res?.message}
          </Message>
        );
        toaster.push(message);
    };

    const handleInventoryCheckbox = (event: any, inventoryId: number) => {
        const checkboxes = inventoryChecked;

        if (event.target.checked) {
            checkboxes[inventoryId] = event.target.checked;
        } else {
            delete checkboxes[inventoryId];
        }

        setInventoryChecked(checkboxes);
    };

    if (fromDate !== '' && event.occurs_at <= fromDate) return <></>
    if (toDate !== '' && event.occurs_at >= toDate) return <></>

    if (city) {
        inventories = inventories.filter((inventory: any) => inventory.event.city.toLowerCase().includes(city.toLowerCase()));
        if (inventories.length === 0) {
            return <></>
        }
    }

    if (venue) {
        inventories = inventories.filter((inventory: any) => inventory.event.venue.toLowerCase().includes(venue.toLowerCase()));
        if (inventories.length === 0) {
            return <></>
        }
    }

    if (section) {
        inventories = inventories.filter((inventory: any) => inventory.section.toLowerCase().includes(section.toLowerCase()))
        if (inventories.length === 0) {
            return <></>
        }
    }

    if (row) {
        inventories = inventories.filter((inventory: any) => inventory.row.toLowerCase().includes(row.toLowerCase()))
        if (inventories.length === 0) {
            return <></>
        }
    }

    if (inventory_id) {
        inventories = inventories.filter((inventory: any) => inventory.inventory_id.toString().toLowerCase().includes(inventory_id.toString().toLowerCase()))
        if (inventories.length === 0) {
            return <></>
        }
    }

    if (name) {
        inventories = inventories.filter((inventory: any) => inventory.description.toLowerCase().includes(name.toLowerCase()))
        if (inventories.length === 0) {
            return <></>
        }
    }

    if (performer) {
        inventories = inventories.filter((inventory: Inventory) => inventory.sb_event?.sb_performer?.name.toLowerCase().includes(performer.toLowerCase()))
        if (inventories.length === 0) {
            return <></>
        }
    }

    const prettySeatTypes = {
        'CONSECUTIVE': 'Consecutive',
        'ALTERNATING': 'Odd/Even',
        'GA': 'GA',
        'PIGGYBACK': 'Piggyback',
    };

    const seatTypesMap = new Map<string, string>(Object.entries(prettySeatTypes));

    function handleEventCheckbox(event: ChangeEvent<HTMLInputElement>, inventories: Inventory[]) {
        const checkboxes = inventoryChecked;
        let checked = event.target.checked;

        inventories.forEach((inventory: Inventory) => {
            const element = document.getElementsByName(String(inventory.inventory_id))[0] as unknown as HTMLInputElement;

            if (element) {
                element.checked = checked;
                if (checked) {
                    checkboxes[inventory.inventory_id] = checked;
                } else {
                    delete checkboxes[inventory.inventory_id];
                }
            }

            setInventoryChecked(checkboxes);
        });
    }

    const handleTableLayout = (inventory: Inventory) => {
        if (!actions) {
            return (
                <>
                    <td className={inventory.sb_vendor_id === loggedUser!.vendor_id && !isActionChange
                        ? "user-sell-page__table-own"
                        : "user-sell-page__table-text user-sell-page_table-section"}
                    >
                        {StockTypes[inventory.stock_type as keyof typeof StockTypes]}
                    </td>
                    <td className={inventory.sb_vendor_id === loggedUser!.vendor_id && !isActionChange
                        ? "user-sell-page__table-own"
                        : "user-sell-page__table-text user-sell-page_table-section"}
                    >
                        {inventory.in_hand_date}
                    </td>
                    <td className={inventory.sb_vendor_id === loggedUser!.vendor_id && !isActionChange
                        ? "user-sell-page__table-own"
                        : "user-sell-page__table-text user-sell-page_table-section"}>
                        {loggedUser!.paid_access
                            ? (loggedUser!.sb_vendor.id !== inventory.sb_vendor_id && (
                                <div>
                                    <p>{inventory.sb_vendor.name}</p>
                                    <p>{inventory.sb_vendor.email}</p>
                                    <p>{inventory.sb_vendor.data.address.phone1}</p>
                                    <p>{inventory.sb_vendor.data.address.phone2}</p>
                                </div>
                            ))
                            : <>
                                {inventory.sb_vendor_id !== loggedUser!.vendor_id && loggedUser!.role_id !== RolesEnum.SuperAdmin ?
                                    <div className="trade-button-container">
                                        {!inventory.hold_id &&
                                            <button style={{border: '1px solid'}}
                                                    onClick={() => openBuyTicketModal(inventory)}
                                                    type="button"
                                                    className="filters-button-apply ">Buy
                                            </button>
                                        }
                                        <button style={{border: '1px solid'}} onClick={() => bid(inventory.id)}
                                                type="button"
                                                className="filters-button-apply">Bid
                                        </button>
                                    </div>
                                    :
                                    null
                                }
                            </>
                        }
                    </td>
                </>
            )
        }

        return null
    };

    const isBulkActionsAllowed = (event: SbEvent) => {
        if (isActionChange) {
            return (
                <>
                    <Dropdown>
                        <Dropdown.Toggle className="btn btn-light p-1 user-sell-page__dropdown-btn">
                            Bulk Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item className="user-sell-page__dropdown-item"
                                           onClick={() => merge()}>
                                Merge
                            </Dropdown.Item>
                            <Dropdown.Item className="user-sell-page__dropdown-item"
                                           onClick={() => openQuoteModal()}>
                                Add to quote
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            )
        } else if (!isActionChange && loggedUser!.role_id !== RolesEnum.SuperAdmin) {
            return (
                <>
                    <button onClick={() => openQuoteModal()}
                            type={"button"}
                            className="filters-button-quote">
                        Add to quote
                    </button>
                </>
            )
        } else if (loggedUser!.role_id === RolesEnum.SuperAdmin) {
            return (<></>)
        }
    }

    const isInventoryActionsAllowed = (inventory: Inventory) => {
        const dropDownItems = [
            {
                label: 'Cancel',
                className: 'user-sell-page__dropdown-item',
                onClick: () => cancel(inventory.id),
                disabled: inventory.status === 'ON_HOLD'
            },
            {
                label: 'Split',
                className: 'user-sell-page__dropdown-item',
                onClick: () => split(inventory.id),
                disabled: (inventory.quantity <= 1 || inventory.status === 'ON_HOLD')
            },


        ]
        if (isActionChange) {
            return (
                <td style={{borderBottom: '1px solid #DAE3EE'}}>
                    <Dropdown>
                        <Dropdown.Toggle className="user-sell-page__action-button">
                            <img src={Cogwheel}
                                 alt="action icon"
                                 className="action-icon dropdown-toggle"
                                 draggable="false"
                            />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item className="user-sell-page__dropdown-item" as={Link}
                                           to={'/user/sell/edit-ticket/' + inventory.id}>
                                Edit
                            </Dropdown.Item>

                            {inventory.local_inventory?.purchase_orders && (
                                <Dropdown.Item className="user-sell-page__dropdown-item" as={Link}
                                               to={'/user/sell/edit-purchase-order/' + inventory.local_inventory?.purchase_orders?.id}>
                                    View PO
                                </Dropdown.Item>
                            )}

                            {dropDownItems.map(dropDownItem => (
                                <Dropdown.Item
                                    key={dropDownItem.label}
                                    className={dropDownItem.className}
                                    onClick={dropDownItem.onClick}
                                    disabled={dropDownItem.disabled}
                                >
                                    {dropDownItem.label}
                                </Dropdown.Item>
                            ))}
                            <Dropdown.Item
                                onClick={() => {
                                    handleCreateInvoiceClick(inventory)
                                }}
                                className={'user-sell-page__dropdown-item'}
                            >
                                Create Invoice
                            </Dropdown.Item>

                            {inventory.status !== 'ON_HOLD'
                                ? <Dropdown.Item className="user-sell-page__dropdown-item"
                                                 onClick={() => createHold(inventory.id)}>
                                    Create Hold
                                </Dropdown.Item>
                                : <></>
                            }
                            {InventoryStatusTypes[inventory.status as keyof typeof InventoryStatusTypes] && inventory.hold_id
                                ?
                                <>
                                    <Dropdown.Item className="user-sell-page__dropdown-item"
                                                   onClick={() => unhold(inventory.hold!.id)}>
                                        Remove Hold
                                    </Dropdown.Item>
                                    <Dropdown.Item className="user-sell-page__dropdown-item"
                                                   onClick={() => editHold(inventory.id)}>
                                        Edit Hold
                                    </Dropdown.Item>
                                </>
                                : null
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            )
        } else {
            return null
        }
    }

    const handleToBroadcast = (id: number, checked: boolean) => {
        editTicketRequest(id, {'broadcast': checked}).then(res => {
            if (res instanceof HttpFailureResponse) {
                alert(res?.message);
            }
        });
    }

    const isBuyPage = (inventory: Inventory) => {
        if (isActionChange) {
            return (
                <>
                    <td className="user-sell-page__table-text">{InventoryStatusTypes[inventory.status as keyof typeof InventoryStatusTypes]}</td>
                    <td className="user-sell-page__table-text">{inventory.internal_notes ?? '—'}</td>
                    <td className="hover-text hover-text_odd">
                        <div className="text-container">{inventory.public_notes}</div>
                        {inventory.public_notes &&
                            <span className="tooltip-text">{inventory.public_notes}</span>
                        }
                    </td>
                    <td className="user-sell-page__table-text">
                        <input name={'to_map'}
                               type='checkbox'
                               defaultChecked={inventory.broadcast}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleToBroadcast(inventory.id, e.target.checked)}/>
                    </td>
                </>
            )
        }
        return <td className={inventory.sb_vendor_id === loggedUser!.vendor_id && !isActionChange
            ? "hover-text_own"
            : "hover-text"}
        >
            <div className="text-container">{inventory.public_notes}</div>
            {inventory.public_notes &&
                <span className="tooltip-text">{inventory.public_notes}</span>
            }
        </td>
    }

    const isOwnInventoryClassName = (inventory: Inventory) => {
        return (inventory.sb_vendor_id === loggedUser!.vendor_id && !isActionChange)
            ? 'user-sell-page__table-own'
            : 'user-sell-page__table-text'
    }

    return (
        <>
            <tr className="user-sell-page__table-title">
                {loggedUser!.role_id === RolesEnum.User
                    ?
                    <td className="user-sell-page__table-title-checkbox">
                        <input type="checkbox" onChange={(event) => handleEventCheckbox(event, inventories)}/>
                    </td>
                    :
                    <td></td>
                }
                <td colSpan={
                    isActionChange
                        ? (loggedUser!.api_keys?.ticombo ? 15 : 14)
                        : (loggedUser!.role_id === RolesEnum.SuperAdmin ? 12 : 8)}
                    className="user-sell-page__table-title-text user-sell-page__table-event-text">
                    <div className={'d-flex align-items-center justify-content-between'}>
                        <div>
                            {setEventData(event)}
                        </div>
                        <div>
                            {isBulkActionsAllowed(event)}
                        </div>
                    </div>
                </td>
            </tr>
            {
                inventories.map((inventory: Inventory, index) =>
                    <React.Fragment key={inventory.inventory_id + index}>
                        <tr className="user-sell-page__table-inventory">
                            {loggedUser!.role_id !== RolesEnum.SuperAdmin ?
                                <td className={isOwnInventoryClassName(inventory)}>
                                    <input name={String(inventory.inventory_id)} type="checkbox"
                                           defaultChecked={inventoryChecked[inventory.inventory_id]}
                                           onChange={(e) => handleInventoryCheckbox(e, inventory.inventory_id)}/>
                                </td>
                                :
                                <td className={isOwnInventoryClassName(inventory)}></td>
                            }
                            {loggedUser!.role_id === RolesEnum.SuperAdmin && (
                                <>
                                    <td className={isOwnInventoryClassName(inventory)}
                                    >
                                        {inventory.sb_vendor?.id}
                                    </td>
                                    <td className={isOwnInventoryClassName(inventory)}
                                    >
                                        {inventory.sb_vendor?.name}
                                    </td>
                                    <td className={isOwnInventoryClassName(inventory)}
                                    >
                                        {inventory.inventory_id}
                                    </td>
                                    <td className={isOwnInventoryClassName(inventory)}
                                    >
                                        {inventory.sb_vendor?.user?.pools?.map((pool) => (pool.name)).join(', ')}
                                    </td>
                                </>
                            )}
                            <td className={isOwnInventoryClassName(inventory)}
                            >
                                {inventory.quantity}
                                {
                                    inventory.status === "ON_HOLD" ?
                                        <p title={'Hold expire ' + inventory.hold_expiry_date}
                                           className="hold-alert">HOLD</p> : undefined
                                }
                            </td>

                            {
                                isActionChange ?
                                    <td className="user-sell-page__table-text">
                                        <Formik
                                            initialValues={{shown_quantity: inventory.shown_quantity, id: inventory.id}}
                                            onSubmit={(values) => {
                                                onChangeValues(values, inventory, 'shown-quantity');
                                            }}
                                            enableReinitialize={true}>
                                            <Form className={'price-container'}>
                                                <DefaultFormikInput
                                                    name={'shown_quantity'}
                                                    className={"price-container__input"}
                                                    placeholder={String(inventory.shown_quantity ?? inventory.quantity)}
                                                    autocomplete={'off'}
                                                ></DefaultFormikInput>
                                                <AppButton data-shown-quantity-button={inventory.id}
                                                           isLoading={loading.includes(`shown-quantity-${inventory.id}`)}
                                                           color={'red'} text={'Set'} type={'submit'} size={'sm'}></AppButton>
                                            </Form>
                                        </Formik>
                                    </td>
                                    : null

                            }

                            {isActionChange ?
                                <td className="user-sell-page__table-text">{inventory.inventory_id}</td>
                                : null
                            }
                            <td className={isOwnInventoryClassName(inventory)}>
                                {inventory.section}
                            </td>
                            <td className={isOwnInventoryClassName(inventory)}>
                                {inventory.row}
                            </td>
                            {isActionChange ?
                                <td className="user-sell-page__table-text">{inventory.low_seat}</td>
                                : null

                            }
                            <td className={isOwnInventoryClassName(inventory)}
                                style={{minWidth: '130px'}}
                            >
                                {
                                    isActionChange ? (
                                        <Formik
                                            initialValues={{unit_amount: inventory.unit_amount, id: inventory.id}}
                                            onSubmit={(values) => {
                                                onChangeValues(values, inventory, 'price');
                                            }}>
                                            <Form className={'price-container with-prefix'}>
                                                <DefaultFormikInput name={'unit_amount'}
                                                                    className={"price-container__input"}
                                                                    placeholder={'Unit Price'}
                                                                    prefix={inventory.currency_icon}
                                                                    autocomplete={'off'}
                                                ></DefaultFormikInput>
                                                <AppButton data-price-button={inventory.id}
                                                           isLoading={loading.includes(`price-${inventory.id}`)}
                                                           color={'red'} text={'Set'} type={'submit'} size={'sm'}></AppButton>
                                            </Form>
                                        </Formik>
                                    ) : (
                                        <>
                                            {!selectedCurrency ? (
                                                <> {inventory.currency_icon}{inventory.unit_amount}</>
                                            ) : (
                                                <>
                                                    {getCurrencySymbol(selectedCurrency, currencies)}
                                                    {(convertedAmounts.find((item) => item.id === inventory.id)?.convertedAmount) || 0}
                                                </>
                                            )}
                                        </>
                                    )}
                            </td>
                            { isActionChange && hasIntegrations ? (
                                <>
                                    <td className={'odd-even-reset'}>{/* empty cell to reset odd-even rule */}</td>
                                    <td className={'user-sell-page__table-custom-price'} style={{minWidth: '180px'}}>
                                        <EventIntegrations inventory={inventory} integrationType={IntegrationsEnum.Ticombo} onUpdatedCallback={getData}></EventIntegrations>
                                    </td>
                                </>
                            ) : null}
                            {isBuyPage(inventory)}
                            <td className={inventory.sb_vendor_id === loggedUser!.vendor_id && !isActionChange
                                ? "user-sell-page__table-own"
                                : "user-sell-page__table-text user-sell-page_table-section"}
                            >
                                {seatTypesMap.get(inventory.seat_type)}
                            </td>
                            {isInventoryActionsAllowed(inventory)}
                            {handleTableLayout(inventory)}
                        </tr>
                        <AppModal
                            headerTitleClassName={'buy-inventory-header'}
                            bodyClassName={'buy-inventory-modal'}
                            title={'Open Bid'}
                            centered={true}
                            form={
                                <BidForm
                                    inventory={inventory}
                                    quantityOptions={addQuantityOptions(inventory)}
                                    selectedCurrency={selectedCurrency}
                                    onClose={handleBidClose}
                                />
                            }
                            show={bidModalId === inventory.id}
                            showHandle={handleBidClose}
                            onClose={() => {
                            }}
                        />
                        <AppModal centered={true} key={'holdModal' + inventory.inventory_id}
                                  show={showHold === inventory.id}
                                  showHandle={handleHoldClose} title={isEditMode ? 'Edit Hold' : 'Create Hold'} form={
                            <HoldForm editMode={isEditMode} event={event} inventory={inventory}
                                      hold={inventory.hold}
                                      showHandle={handleHoldClose}/>
                        } onClose={() => {
                            getData();
                        }}/>
                        <AppModal key={'splitModal' + inventory.inventory_id} show={showSplit === inventory.id}
                                  showHandle={handleSplitClose} title={'Split Inventory'} form={
                            <SplitForm showHandle={handleSplitClose} inventory={inventory}/>
                        } onClose={() => {
                            getData();
                        }}/>
                        <AppModal key={'duplicateModal' + inventory.inventory_id}
                                  show={showDuplicate === inventory.id}
                                  showHandle={handleDuplicateClose} title={'Copy Inventory'} form={
                            <DuplicateForm showHandle={handleDuplicateClose} inventory={inventory}/>
                        } onClose={() => {
                            getData();
                        }}/>
                        <AppModal key={'buyTicketModal' + inventory.inventory_id}
                                  centered={true}
                                  show={buyModalOpen === inventory.id}
                                  showHandle={handleBuyTicketClose}
                                  title={'Buy inventory'}
                                  headerTitleClassName={'buy-inventory-header'}
                                  bodyClassName={'buy-inventory-modal'}
                                  form={
                                      <BuyTicketForm
                                          quantityOptions={addQuantityOptions(inventory)}
                                          isSubmitting={isSubmitting}
                                          event={event}
                                          inventory={inventory}
                                          selectedCurrency={selectedCurrency}
                                          showHandle={handleBuyTicketClose}
                                      />
                                  } onClose={() => {
                            getData();
                        }}/>
                    </React.Fragment>
                )

            }
            <AppModal title={'Quote Builder'}
                      centered={true}
                      key={'quoteBuilderModal'}
                      form={
                          <QuoteBuilder data={selectedInventoryData} setData={setSelectedInventoryData}/>
                      }
                      show={quoteModalOpen}
                      showHandle={handleQuoteModalClose}
                      onClose={() => getData()}
            />
        </>
    )
}

export default TableEventBlock;
