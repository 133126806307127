import React, { FC, useRef, useState } from 'react';
import "./AppSelect.scss";
import SelectArrow from '../../../assets/images/other/select-arrow.svg';
import {Field, useFormikContext} from "formik";
import useHandleOutsideClick from '../../../hooks/useHandleOutsideClick';

interface Props {
    items: { name: string | number | JSX.Element, value: string | number }[],
    value: string | number,
    name: string,
    placeholder?: string,
    customLiClass?: string,
    changeCallback?: (value: any) => void
}

const AppSelect: FC<Props> = ({items, value, name, placeholder, customLiClass = '', changeCallback}) => {
    const {setFieldValue} = useFormikContext();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const selectRef = useRef<HTMLDivElement>(null);

    useHandleOutsideClick(selectRef, () => {
        setIsOpen(false);
    });

    const handleSelect = (value: string | number) => {
        setFieldValue(name, value);
        changeCallback && changeCallback(value);
    };

    const displayValue = items.find((item) => item.value === value)?.name;
    return (
        <div className="app-select" onClick={() => setIsOpen(!isOpen)} ref={selectRef}>
            <div className={"app-select__header"}>
                <div className="app-select__header-name">
                    {placeholder ? (value ? displayValue : <p className="app-select__body-placeholder">{placeholder}</p>) : displayValue}
                </div>
                <span className={isOpen ? "app-select__header-arrow-up" : "app-select__header-arrow"}>
                    <img src={SelectArrow} alt="arrow"/>
                </span>
            </div>
            <ul className={isOpen ? "" : "hidden"}>
                {items.map((item, index) => (
                    <li key={index}
                        className={' ' + customLiClass}
                        data-value={item.value}
                        onClick={() => handleSelect(item.value)}
                    >
                        {item.name}
                    </li>
                ))}
            </ul>
            <Field type={'hidden'} name={name} className="d-none" value={value}/>
        </div>
    )
}

export default AppSelect;
