import React, {FC} from "react";
import {FormikValues} from "formik";
import {AmountMapReport} from "../../../../../models/AmountMapReport";
import AppTable from "../../../../../ui/Table/AppTable/AppTable";

interface Props {
    pagination: {
        paginate: (params?: any) => Promise<void>,
        items: AmountMapReport[],
        setItems: React.Dispatch<React.SetStateAction<AmountMapReport[]>>,
        currentPage: number,
        setCurrentPage: (v: number) => void,
        totalItems: number,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>,
        lastPage: number,
        perPage: number,
        setPerPage: (v: number) => void,
        isLoading: boolean,
        error: string
    };
    selectedPage: number;
    filterValues: FormikValues;
}

const AmountReportsTable: FC<Props> = ({pagination, selectedPage, filterValues}) => {
    const tableHeaders = [
        'Email',
        'LTG Events',
        'Ticombo Events',
        'Stubhub Events',
        'LTG Inventories',
        'Ticombo Inventories',
        'Stubhub Sections',
        'Total'
    ];

    return (
        <AppTable
            columns={tableHeaders}
            pagination={{
                lastPage: pagination.lastPage,
                currentPage: pagination.currentPage,
                total_items: pagination.totalItems
            }}
            onPageChange={(page, perPage) => {
                pagination.setCurrentPage(page);
                pagination.setPerPage(perPage);
                pagination.paginate(filterValues);
            }}
            withAutoHeight={false}
        >
            <>
                {pagination.items.length
                    ? pagination.items?.map((record, index) =>
                        <React.Fragment key={'SbEvents' + index}>
                            <tr data-row={index}>
                                <td>{record.user.email}</td>
                                <td>
                                    {record.ltg_event}
                                </td>
                                <td>
                                    {record.ticombo_event}
                                </td>
                                <td>
                                    {record.stubhub_event}
                                </td>
                                <td>
                                    {record.ltg_inventory}
                                </td>
                                <td>
                                    {record.ticombo_inventory}
                                </td>
                                <td>
                                    {record.stubhub_section}
                                </td>
                                <td>
                                    {
                                        Number(record.ltg_event) + Number(record.ticombo_event) +
                                        Number(record.ltg_inventory) + Number(record.ticombo_inventory) +
                                        Number(record.stubhub_event) + Number(record.stubhub_section)
                                    }
                                </td>
                            </tr>
                        </React.Fragment>
                    )
                    :
                    <tr className={"events-page__table__tr "}>
                        <td className={"text-center"} colSpan={6}>No statistics</td>
                    </tr>
                }
            </>
        </AppTable>
    )
}

export default AmountReportsTable;
