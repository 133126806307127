import "./AppSmartSearch.scss";
import React, {FC, useEffect, useState} from "react";
import DefaultFormikInput from "../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import {useFormikContext} from "formik";
import useComponentVisible from "../../../hooks/useComponentVisible";
import {GlobalStatusCodesEnum} from "../../../enums/ResponseStatuses/GlobalStatusCodesEnum";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";

interface Props {
    name: string,
    placeholder: string,
    requestFunction: (param: string) => Promise<any>,
    valuesKey: string,
    customClass?: string,
    inputId?: string,
    showErrorOnFocus?: boolean,
    setDataFromRequest?: React.Dispatch<React.SetStateAction<any>>,
    initialInputItem?: Record<string, any> & { id: number | string },
    showIcon?: boolean
}

const AppSmartSearch: FC<Props> = ({
                                       name,
                                       placeholder,
                                       requestFunction,
                                       valuesKey,
                                       customClass,
                                       inputId,
                                       showErrorOnFocus,
                                       setDataFromRequest,
                                       initialInputItem,
                                       showIcon = false
                                   }) => {
    showErrorOnFocus = typeof showErrorOnFocus === 'boolean' ? showErrorOnFocus : true;

    inputId = inputId ?? Math.random().toString(36).slice(2);
    const {setFieldValue} = useFormikContext();
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);

    const [search, setSearch] = useState('');
    const [entityId, setEntityId] = useState<number | string | null | undefined>(null);
    const [items, setItems] = useState<any[]>([]);
    const [isInputFocused, setIsInputFocused] = useState(false);

    useEffect(() => {
        if (search && search.length >= 1) {
            const timeout = setTimeout(() => {
                requestFunction(search)
                    .then((res: HttpSuccessResponse<GlobalStatusCodesEnum, any>) => {
                        setItems(res.data);
                        setIsComponentVisible(true);
                    });
            }, 800);

            return () => clearTimeout(timeout);
        }
    }, [search]);

    useEffect(() => {
        if(initialInputItem) {
            setFieldValue(name, initialInputItem[valuesKey]);
            setEntityId(initialInputItem?.id)
        }
    }, [initialInputItem, name, setFieldValue, valuesKey]);

    const handleInputClick = (item: any) => {
        setFieldValue(name, item[valuesKey]);
        setIsComponentVisible(false);
        setEntityId(item['id']);

        if (setDataFromRequest) setDataFromRequest(item);
    };

    return (
        <div className="app-smart-search">
            <label className={showIcon && search === '' && !isInputFocused ? 'lens-icon' : 'd-none'}></label>
            <DefaultFormikInput
                id={inputId}
                name={name}
                placeholder={placeholder}
                autocomplete={'off'}
                class={`w-100 ${customClass}`}
                data-entity-id={entityId}
                showErrorOnFocus={showErrorOnFocus}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearch(e.target.value);
                    setFieldValue(name, e.target.value);
                    setEntityId(null)
                }}
                onFocus={() => {
                    setIsInputFocused(true);
                    setIsComponentVisible(true)
                }}
                onBlur={() => {
                    setIsInputFocused(false);
                }}
            />
            {
                isComponentVisible ?
                    <ul className="app-smart-search-list" ref={ref}>
                        {items.length ?
                            items.map((item, index) => (
                                <li className="app-smart-search-list__item"
                                    key={`smart-search-${name}-${item?.id ?? index}`}
                                    onClick={() => {
                                        handleInputClick(item)
                                    }}
                                >
                                    {item[valuesKey]}
                                </li>
                            )) :
                            <li className="app-smart-search-list__item">No results</li>
                        }
                    </ul>
                    :
                    null
            }
        </div>
    )
}

export default AppSmartSearch;
