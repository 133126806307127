import {LTGEvent, SbEvent} from "../../../../../models/Event";
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import {Form, Formik} from "formik";
import DefaultFormikInput from "../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import FormikDateInput from "../../../../../components/Inputs/FormikDateInput/FormikDateInput";
import "./MapForm.scss";
import {dateToLocaleString} from "../../../../../helpers/DateFormatter";
import {getEventsToMap, getMappedEvents, patchMappedEvents} from "../../../../../@api/Event";
import {EventsFilters} from "../../../../../@interfaces/EventsFilters";
import {HttpFailureResponse} from "../../../../../@api/Responses/HttpFailureResponse";
import {TicomboEvent} from "../../../../../models/TicomboEvent";
import {StubhubEvent} from "../../../../../models/StubhubEvent";
import DefaultSpinner from "../../../../../ui/Spinners/DefaultSpinner/DefaultSpinner";

interface Props {
    event: SbEvent;
    showHandle: (ltgEvents: boolean, tcEvents: boolean, shEvents: boolean) => void;
}

interface EventsArray {
    ltgEvents?: LTGEvent[],
    tcEvents?: TicomboEvent[],
    shEvents?: StubhubEvent[]
}

type Option = 'add' | 'delete'

const MapForm: FC<Props> = ({event, showHandle}) => {
    const formValues = [
        {key: 'name', value: 'Search events by name'},
        {key: 'venue', value: 'Search events by venue'},
        {key: 'city', value: 'Search events by city'},
    ];

    const todayDate = () => {
        const date = new Date();
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;

        return new Date(date.getTime() - userTimezoneOffset);
    };

    const filterInitial = {name: '', venue: '', city: '', category: '', from_date: todayDate(), to_date: null};
    const [filterValues, setFilterValues] = useState(filterInitial);
    const [events, setEvents] = useState<EventsArray>({ltgEvents: [], tcEvents: [], shEvents: []});
    const [mappedEvents, setMappedEvents] = useState<EventsArray>({ltgEvents: [], tcEvents: [], shEvents: []});
    const [isEventsMapToLoading, setIsEventsMapToLoading] = useState(false);
    const [searchStatus, setSearchStatus] = useState(false);

    useEffect(() => {
        const modal = document.getElementsByClassName('modal-dialog')[0];

        modal.classList.add('map');

        getMappedEvents(event.id)
            .then((res) => {
                if (res instanceof HttpFailureResponse) {
                    alert(res?.message);
                } else {
                    setMappedEvents(res.data);
                }
            });
    }, []);

    const handleOnDatesChange = (date: Date | null, name: string) => {
        (filterValues as any)[name] = date;
        setFilterValues(filterValues);
    };

    const handleOnFiltersChange = (event: FormEvent) => {
        const target = event.target as HTMLInputElement;

        (filterValues as any)[target.name] = target.value;
        setFilterValues(filterValues);
    };

    const handleFiltersSubmit = async (values: EventsFilters) => {
        setIsEventsMapToLoading(true);
        setSearchStatus(true);

        const res = await getEventsToMap(values);

        setIsEventsMapToLoading(false);

        if (res instanceof HttpFailureResponse) {
            alert(res?.message);
        } else {
            setEvents(res.data);
        }
    };

    function switchChecked<T extends { id: string | number }>
    (id: string, from: T[] | undefined, to: T[] | undefined, option: Option) {
        const searchingEvent = from?.findIndex((key) => {
            return key.id == id;
        });

        if (searchingEvent === undefined || from === undefined) {
            return;
        }

        if (option === 'add') {
            if (to && to.length > 0) {
                from.push(to[0]);
            }
            to?.splice(0, to.length);
        }
        to?.push(from[searchingEvent]);
        from?.splice(searchingEvent, 1);
    }

    const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case 'ltgEvent':
                (event.target.checked)
                    ? switchChecked(event.target.value, events.ltgEvents, mappedEvents.ltgEvents, 'add')
                    : switchChecked(event.target.value, mappedEvents.ltgEvents, events.ltgEvents, 'delete');

                setMappedEvents({...mappedEvents});
                setEvents({...events});
                break;

            case 'tcEvent':
                (event.target.checked)
                    ? switchChecked(event.target.value, events.tcEvents, mappedEvents.tcEvents, 'add')
                    : switchChecked(event.target.value, mappedEvents.tcEvents, events.tcEvents, 'delete');

                setMappedEvents({...mappedEvents});
                setEvents({...events});
                break;

            case 'shEvent':
                (event.target.checked)
                    ? switchChecked(event.target.value, events.shEvents, mappedEvents.shEvents, 'add')
                    : switchChecked(event.target.value, mappedEvents.shEvents, events.shEvents, 'delete');

                setMappedEvents({...mappedEvents});
                setEvents({...events});
                break;
        }
    };

    const handleSubmit = () => {
        patchMappedEvents(event.id, mappedEvents)
            .then((res) => {
                if (res instanceof HttpFailureResponse) {
                    alert(res.message);
                } else {
                    showHandle(res.data.ltgEvents, res.data.tcEvents, res.data.shEvents);
                }
            });
    };

    const eventsLoadingOrEmpty = () => {
        return (isEventsMapToLoading
                ? <DefaultSpinner></DefaultSpinner>
                : (
                    searchStatus
                        ? <span className="map__events_item map__events_item-no">No events were found</span>
                        : <span className="map__events_item map__events_item-no">No Events</span>
                )
        );
    };

    return (
        <>
            <div className="map__event-info">
                <h5>{event.name}, {event.venue}, {event.city}, {dateToLocaleString(event.occurs_at)}</h5>
            </div>
            <div className="map__filters">
                <Formik
                    initialValues={filterInitial}
                    onSubmit={async (values: EventsFilters) => {
                        await handleFiltersSubmit(values);
                    }}
                >
                    {({resetForm}) => {
                        return (
                            <Form className="map__filters_form" onChange={handleOnFiltersChange}>
                                <div className="map__filters_form_wrapper">
                                    {formValues.map(formValue => (
                                        <DefaultFormikInput
                                            key={formValue.key}
                                            name={formValue.key}
                                            placeholder={formValue.value}
                                            autocomplete={'off'}
                                            class={'filters-search'}
                                        ></DefaultFormikInput>
                                    ))}
                                </div>
                                <div className="map__filters_form_wrapper">
                                    <FormikDateInput
                                        name={'from_date'}
                                        placeholderText={'From date'}
                                        showTimeSelect
                                        autoComplete="off"
                                        sendDate={(date: Date | null, name: string) => handleOnDatesChange(date, name)}
                                    />
                                    <FormikDateInput
                                        name={'to_date'}
                                        placeholderText={'To date'}
                                        showTimeSelect
                                        autoComplete="off"
                                        sendDate={(date: Date | null, name: string) => handleOnDatesChange(date, name)}
                                    />
                                    <div className="events-page__filters__component">
                                        <button type="reset" onClick={() => resetForm({values: filterInitial})}
                                                className="events-page__filters__button filters-button-reset">
                                            Reset
                                        </button>
                                    </div>
                                    <div className="events-page__filters__component">
                                        <button type="submit"
                                                className="events-page__filters__button filters-button-apply">
                                            Apply Filters
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <div className="map__events">
                <div className="map__events_block">
                    <div>Live Ticket Group</div>
                    <div className="map__events_group">
                        {mappedEvents.ltgEvents?.map((ltgEvent) => (
                            <div key={'ltgEvent' + ltgEvent.id} className="map__events_item">
                                <input id={'ltgEvent' + ltgEvent.id} type="checkbox"
                                       checked={true} name="ltgEvent" value={ltgEvent.id}
                                       className="map__events_item_input"
                                       onChange={handleCheck}/>
                                <label className="map__events_item_label"
                                       htmlFor={'ltgEvent' + ltgEvent.id}>{ltgEvent.name}, {ltgEvent.ltg_venue?.name}, {ltgEvent.ltg_venue?.city}, {dateToLocaleString(ltgEvent.start_date_time)}</label>
                            </div>
                        ))}
                        {
                            events.ltgEvents?.length && !isEventsMapToLoading
                                ? events.ltgEvents?.map((ltgEvent) => (
                                    <div key={'ltgEvent' + ltgEvent.id} className="map__events_item">
                                        <input id={'ltgEvent' + ltgEvent.id} type="checkbox"
                                               name="ltgEvent" value={ltgEvent.id} className="map__events_item_input"
                                               onChange={handleCheck}/>
                                        <label className="map__events_item_label"
                                               htmlFor={'ltgEvent' + ltgEvent.id}>{ltgEvent.name}, {ltgEvent.ltg_venue?.name}, {ltgEvent.ltg_venue?.city}, {dateToLocaleString(ltgEvent.start_date_time)}</label>
                                    </div>
                                ))
                                : eventsLoadingOrEmpty()
                        }
                    </div>
                </div>

                <div className="map__events_block">
                    <div>Ticombo</div>
                    <div className="map__events_group">
                        {mappedEvents.tcEvents?.map((tcEvent) => (
                            <div key={'tcEvent' + tcEvent.id} className="map__events_item">
                                <input id={'tcEvent' + tcEvent.id} type="checkbox"
                                       checked={true} name="tcEvent" value={tcEvent.id}
                                       className="map__events_item_input"
                                       onChange={handleCheck}/>
                                <label className="map__events_item_label"
                                       htmlFor={'tcEvent' + tcEvent.id}>{tcEvent.name}, {tcEvent.venue.name}, {tcEvent.venue.location?.city}, {dateToLocaleString(tcEvent.date.start)}</label>
                            </div>
                        ))}
                        {
                            events.tcEvents?.length && !isEventsMapToLoading
                                ? events.tcEvents?.map((tcEvent) => (
                                    <div key={'tcEvent' + tcEvent.id} className="map__events_item">
                                        <input id={'tcEvent' + tcEvent.id} type="checkbox"
                                               name="tcEvent" value={tcEvent.id} className="map__events_item_input"
                                               onChange={handleCheck}/>
                                        <label className="map__events_item_label"
                                               htmlFor={'tcEvent' + tcEvent.id}>{tcEvent.name}, {tcEvent.venue.name}, {tcEvent.venue.location?.city}, {dateToLocaleString(tcEvent.date.start)}</label>
                                    </div>
                                ))
                                : eventsLoadingOrEmpty()
                        }
                    </div>
                </div>

                <div className="map__events_block">
                    <div>Stubhub</div>
                    <div className="map__events_group">
                        {mappedEvents.shEvents?.map((shEvent) => (
                            <div key={'shEvent' + shEvent.id} className="map__events_item">
                                <input id={'shEvent' + shEvent.id} type="checkbox"
                                       checked={true} name="shEvent" value={shEvent.id}
                                       className="map__events_item_input"
                                       onChange={handleCheck}/>
                                <label className="map__events_item_label"
                                       htmlFor={'shEvent' + shEvent.id}>{shEvent.name}, {shEvent.venue.name}, {shEvent.venue.city}, {dateToLocaleString(shEvent.event_date_local)}</label>
                            </div>
                        ))}
                        {
                            events.shEvents?.length && !isEventsMapToLoading
                                ? events.shEvents?.map((shEvent) => (
                                    <div key={'shEvent' + shEvent.id} className="map__events_item">
                                        <input id={'shEvent' + shEvent.id} type="checkbox"
                                               name="shEvent" value={shEvent.id} className="map__events_item_input"
                                               onChange={handleCheck}/>
                                        <label className="map__events_item_label"
                                               htmlFor={'shEvent' + shEvent.id}>{shEvent.name}, {shEvent.venue.name}, {shEvent.venue.city}, {dateToLocaleString(shEvent.event_date_local)}</label>
                                    </div>
                                ))
                                : eventsLoadingOrEmpty()
                        }
                    </div>
                </div>
            </div>
            <button
                className="map__submit events-page__filters__button filters-button-apply"
                type="button" onClick={handleSubmit}>
                Save
            </button>
        </>
    );
};

export default MapForm;
