import React, {FC, useEffect, useState} from "react";
import usePagination from "../../../helpers/UsePagination";
import {Accordion, Container} from "react-bootstrap";
import {Field, Form, Formik} from "formik";
import {handleOnDatesChange, handleOnFiltersChange, handleReset} from "../../../helpers/Filters";
import FormikDateInput from "../../../components/Inputs/FormikDateInput/FormikDateInput";
import DefaultFormikInput from "../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import DefaultSpinner from "../../../ui/Spinners/DefaultSpinner/DefaultSpinner";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import {purchaseOrdersPaginateRequest} from "../../../@api/PurchaseOrders";
import {StockTypes} from "../../../enums/Skybox/StockTypes";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import {getCurrencySymbol} from "../../../helpers/CurrencyConverter";
import AppButton from "../../../ui/Buttons/AppButton/AppButton";
import {Link, useNavigate, useLocation} from "react-router-dom";
import {Inventory} from "../../../models/Inventory";


interface FilterProps {
    from_date: Date | string | undefined;
    to_date: Date | string | undefined;
    sort_by: string;
    id: string,
    vendor: string,
    delivery_method: string,
}

const PurchasesPage: FC = () => {
    const tableHeaders = [
        'P.O. number',
        'P.O. date',
        'Vendor',
        'Created by',
        'Shipping',
        'Payment method',
        'QTY',
        'Section',
        'Row',
        'Unit cost',
        'Total cost',
        'Currency',
        'Payment status',
        'Purchase status',
        'Consignment',
        'Received',
        'Actions'
    ];

    const deliveryMethods = [
        'Hard',
        'E-Ticket',
        'Flash',
        'Mobile QR',
        'Mobile Transfer',
        'Paperless Walk-In',
        'Paperless Gift Card'
    ];

    const filterInitial: FilterProps = {
        from_date: undefined,
        to_date: undefined,
        sort_by: 'ASC',
        id: '',
        vendor: '',
        delivery_method: '',
    };

    const dateFilters = [
        {name: 'from_date', placeholder: 'Date From'},
        {name: 'to_date', placeholder: 'Date To'},
    ];

    const pagination = usePagination<Inventory>(purchaseOrdersPaginateRequest);
    const currencies = useSelector((state: RootState) => state.currency.currencies);
    const [filterValues, setFilterValues] = useState<FilterProps>(filterInitial);
    const navigate = useNavigate();

    useEffect(() => {
        pagination.paginate(filterInitial);
    }, []);


    const handleRowClick = (purchaseOrderId:number) => {
        const redirectUrl = `/user/sell/edit-purchase-order/${purchaseOrderId}`;
        navigate(redirectUrl);
    };

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>Purchases</h1>
            <div className="mb-3">
                <Accordion>
                    <Accordion.Item eventKey={'0'}>
                        <Accordion.Header>
                            Filters
                        </Accordion.Header>
                        <Accordion.Body>
                            <Formik
                                initialValues={filterInitial}
                                onSubmit={async (values) => {
                                    pagination.setPerPage(pagination.perPage);
                                    await pagination.paginate(values);
                                }}
                            >
                                {({resetForm}) => {
                                    return (
                                        <Form className="table__filters"
                                              onChange={(event) => handleOnFiltersChange(event, filterValues, setFilterValues)}>
                                            <div className="table__filters__wrapper">
                                                {dateFilters.map((data, index) => (
                                                    <FormikDateInput
                                                        key={index + 'Dates'}
                                                        name={data.name}
                                                        placeholderText={data.placeholder}
                                                        showTimeSelect
                                                        autoComplete={'off'}
                                                        sendDate={(date: string, name: string) =>
                                                            handleOnDatesChange(date, name as keyof FilterProps, filterValues, setFilterValues)
                                                        }
                                                    />
                                                ))}
                                                <DefaultFormikInput
                                                    key={'id'}
                                                    name={'id'}
                                                    placeholder={'Search by P.O. ID'}
                                                    autocomplete={'off'}
                                                    class={'filters-search'}
                                                ></DefaultFormikInput>
                                                <DefaultFormikInput
                                                    key={'vendor'}
                                                    name={'vendor'}
                                                    placeholder={'Search P.O. by Vendor'}
                                                    autocomplete={'off'}
                                                    class={'filters-search'}
                                                ></DefaultFormikInput>
                                                <Field name="delivery_method" as="select">
                                                    <option disabled value="" selected>Select method</option>
                                                    {deliveryMethods.map((option) => (
                                                        <option key={option} value={option} label={option}/>
                                                    ))}
                                                </Field>
                                            </div>
                                            <div>
                                                <div className="table__filters__wrapper">
                                                    <button type="reset"
                                                            className="filters-button-reset"
                                                            onClick={async () => {
                                                                await handleReset(resetForm, filterInitial, pagination, setFilterValues);
                                                            }}
                                                    >
                                                        Reset
                                                    </button>
                                                    <button type="submit" className="filters-button-apply">
                                                        Apply Filters
                                                    </button>

                                                </div>
                                            </div>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <AppTable
                columns={tableHeaders}
                pagination={{
                    lastPage: pagination.lastPage,
                    currentPage: pagination.currentPage,
                    total_items: pagination.totalItems
                }}
                onPageChange={(page, perPage) => {
                    pagination.setCurrentPage(page);
                    pagination.setPerPage(perPage);
                    pagination.paginate(filterValues);
                }}>
                {pagination.isLoading
                    ?
                    <tr>
                        <td className={'no-border-td'} colSpan={1000}>
                            <DefaultSpinner/>
                        </td>
                    </tr>
                    :
                    <> {pagination.items.length ? (
                            pagination.items.filter(
                                (inventory, index, self) =>
                                index === self.findIndex((inv) => inv.purchase_order_id === inventory.purchase_order_id)
                            ).map((inventory, index) =>
                                <tr data-row={index} key={inventory.id + index}>
                                    <td onClick={() => handleRowClick(inventory.purchase_order_id)} className='clickable-td'>
                                        {inventory.purchase_order_id}
                                    </td>
                                    <td>{dateToLocaleString(inventory.purchase_orders?.created_at)}</td>
                                    <td>{inventory.purchase_orders?.user_vendor?.display_name}</td>
                                    <td>{inventory.purchase_orders?.user?.name}</td>
                                    <td>{StockTypes[inventory.stock_type as keyof typeof StockTypes]}</td>
                                    <td>-</td>
                                    <td>{inventory.quantity}</td>
                                    <td>{inventory.section}</td>
                                    <td>{inventory.row}</td>
                                    <td>{getCurrencySymbol(inventory.currency_symbol, currencies)}{Math.ceil(inventory.unit_amount)}</td>
                                    <td>{getCurrencySymbol(inventory.currency_symbol, currencies)}{inventory.unit_amount * inventory.quantity}</td>
                                    <td>{inventory.currency_symbol}</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>{inventory.consignment}</td>
                                    <td>-</td>
                                    <td>
                                        <Link to={`/user/sell/edit-purchase-order/${inventory.purchase_order_id}`}>
                                            <AppButton text={'Edit P.O.'}/>
                                        </Link>
                                    </td>
                                </tr>
                            )
                        )
                        :
                        null
                    }
                    </>}

            </AppTable>
        </Container>
    )
}

export default PurchasesPage;
