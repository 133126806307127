import React, {FC, useEffect} from "react";
import {Form, Formik} from "formik";
import DefaultFormikInput from "../../Inputs/DefaultFormikInput/DefaultFormikInput";
import FormikDateInput from "../../Inputs/FormikDateInput/FormikDateInput";
import './EventsComponent.scss';
import {Event, LTGEvent, P1Event, SbEvent} from "../../../models/Event";
import {TicomboEvent} from "../../../models/TicomboEvent";
import {Log} from "../../../models/Log";
import {AmountMapReport} from "../../../models/AmountMapReport";
import {AcMilanEvent} from "../../../models/AcMilanEvent";
import { FormikFormChange, FormikFormReset } from '../../../@interfaces/FormikHelpers';
import { EventPageFilters } from '../../../pages/Admin/EventsPage/EventsPage';
import {handleOnFiltersChange} from "../../../helpers/Filters";
import AppButton from '../../../ui/Buttons/AppButton/AppButton';
import AppLoader from '../../../ui/Loader/AppLoader/AppLoader';

interface EventsComponentProps {
    pagination: {
        paginate: (params?: any) => Promise<void>,
        items: Event[] | P1Event[] | LTGEvent[] | TicomboEvent[] | SbEvent[] | AcMilanEvent[] | Log[] | AmountMapReport[],
        setItems: React.Dispatch<React.SetStateAction<Event[]>> | React.Dispatch<React.SetStateAction<P1Event[]>> | React.Dispatch<React.SetStateAction<LTGEvent[]>> | React.Dispatch<React.SetStateAction<TicomboEvent[]>> | React.Dispatch<React.SetStateAction<SbEvent[]>> | React.Dispatch<React.SetStateAction<AcMilanEvent[]>> | React.Dispatch<React.SetStateAction<Log[]>> | React.Dispatch<React.SetStateAction<AmountMapReport[]>>;
        currentPage: number,
        setCurrentPage: (v: number) => void,
        totalItems: number,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>,
        lastPage: number,
        perPage: number,
        setPerPage: (v: number) => void,
        isLoading: boolean,
        error: string
    };
    filterInitial: EventPageFilters;
    formValues: { key: string, value: string }[][];
    filterValues: any;
    setFilterValues: React.Dispatch<React.SetStateAction<any>>;
    selectedPage: number;
    setSelectedPage: React.Dispatch<React.SetStateAction<number>>;
    tableComponent: JSX.Element;
    pageType?: string;
    customFilters?: (f?: FormikFormChange<EventPageFilters>, r?: FormikFormReset<EventPageFilters>) => JSX.Element;
    bottomFilterPanel?: JSX.Element;
    topCustomFilters?: JSX.Element;
}

const EventsComponent: FC<EventsComponentProps> = (
    {
        pagination,
        filterInitial,
        formValues,
        filterValues,
        setFilterValues,
        selectedPage,
        setSelectedPage,
        tableComponent,
        pageType,
        customFilters,
        bottomFilterPanel,
        topCustomFilters
    }
) => {
    useEffect(() => {
        pagination.paginate(filterInitial);
    }, []);

    const handleOnDatesChange = (date: Date | null, name: string) => {
        filterValues[name] = date;
        setFilterValues(filterValues);
    };

    const handleSubmit = async (values: { from_date: Date | null }) => {
        pagination.setPerPage(pagination.perPage);
        await pagination.paginate(values);
        setSelectedPage(1);
        pagination.setCurrentPage(selectedPage);
    };

    const handleReset = async (resetForm: FormikFormReset<EventPageFilters>) => {
        resetForm({values: filterInitial});
        setFilterValues(filterInitial);
        pagination.setPerPage(pagination.perPage);
        await pagination.paginate(filterInitial);
    };

    const dates = () => {
        return (
            <>
                <div className="events-page__filters__component">
                    <FormikDateInput
                        name={'from_date'}
                        placeholderText={'From date'}
                        showTimeSelect
                        autoComplete='off'
                        sendDate={(date: Date | null, name: string) => handleOnDatesChange(date, name)}
                    />
                </div>
                <div className="events-page__filters__component">
                    <FormikDateInput
                        name={'to_date'}
                        placeholderText={'To date'}
                        showTimeSelect
                        autoComplete='off'
                        sendDate={(date: Date | null, name: string) => handleOnDatesChange(date, name)}
                    />
                </div>
            </>
        );
    };

    const buttons = (resetForm: FormikFormReset<EventPageFilters>) => {
        return (
            <>
                <div className="events-page__filters__component d-flex justify-content-end gap-2">
                    <button type='reset' onClick={() => handleReset(resetForm)}
                            className="events-page__filters__button filters-button-reset w-100">
                        Reset
                    </button>
                    <AppButton className={'filters-button-apply'} text={'Apply'} disabled={pagination?.isLoading} type={'submit'} color={'red'} />
                </div>
            </>
        );
    }

    return (
        <div className="events-page">
            <div className="events-page__filters">
                <Formik
                    initialValues={filterInitial}
                    onSubmit={async (values) => {
                        await handleSubmit(values);
                    }}
                >
                    {({resetForm, setValues}) => {
                        return (
                            <Form className="filters-form" onChange={(event) => handleOnFiltersChange(event, filterValues, setFilterValues)}>
                                <div key={formValues[0].map(formField => formField.key).join('')}
                                     className="events-page__filters__wrapper">
                                    {formValues[0].map(formField => (
                                        <DefaultFormikInput
                                            key={formField.key}
                                            name={formField.key}
                                            placeholder={formField.value}
                                            autocomplete={'off'}
                                            class={'filters-search'}
                                        ></DefaultFormikInput>
                                    ))}
                                    {pageType === 'sb' ?
                                        <>
                                            {dates()}
                                            {formValues[1]?.map(formField => (
                                                <DefaultFormikInput
                                                    key={formField.key}
                                                    name={formField.key}
                                                    placeholder={formField.value}
                                                    autocomplete={'off'}
                                                    class={'filters-search'}
                                                ></DefaultFormikInput>
                                            ))}
                                            {topCustomFilters
                                                ? <>
                                                    {topCustomFilters}
                                                </>
                                                : <></>
                                            }
                                            {buttons(resetForm)}
                                        </> :
                                        <></>
                                    }

                                </div>
                                <div className="events-page__filters__wrapper">
                                    {customFilters ? customFilters(setValues, resetForm) : null}
                                    {topCustomFilters
                                        ? <></>
                                        : <>
                                            {dates()}
                                            {pageType === 'p1' ?
                                                <></> :
                                                <>{buttons(resetForm)}</>
                                            }
                                        </>
                                    }
                                    {pageType === 'p1' ?
                                        <>{buttons(resetForm)}</> :
                                        <></>
                                    }
                                </div>
                                {bottomFilterPanel && (
                                    <div className="events-page__filters__wrapper">
                                        {bottomFilterPanel}
                                    </div>
                                )}
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            {!pagination?.isLoading ? tableComponent : (
                <div className={'my-5'}>
                    <AppLoader />
                </div>
            )}
        </div>
    );
}

export default EventsComponent;
