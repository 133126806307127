import React, {FC, useEffect, useState} from "react";
import usePagination from "../../../helpers/UsePagination";
import {getAllPossibleTags} from "../../../@api/CustomTags";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import {Accordion, Container} from "react-bootstrap";
import AppButton from "../../../ui/Buttons/AppButton/AppButton";
import {Field, Form, Formik} from "formik";
import {handleOnFiltersChange, handleReset} from "../../../helpers/Filters";
import AppSmartSearch from "../../../ui/Inputs/AppSmartSearch/AppSmartSearch";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import AppModal from "../../../ui/Modal/AppModal/AppModal";
import {UserVendor} from "../../../models/UserVendor";
import {
    userVendorSearchByDisplayName,
    userVendorSearchById,
    userVendorsPaginateRequest
} from "../../../@api/UserVendors";
import {VendorTypes} from "../../../enums/Skybox/VendorTypes";
import {getUserVendorTags} from "../../../@api/VendorTags";
import PurchasedInventories from "./PurchasedInventories/PurchasedInventories";
import './UserVendorsPage.scss';
import CustomerOrVendorForm from "../CustomersPage/CustomerOrVendorForm/CustomerOrVendorForm";
import AppLoader from "../../../ui/Loader/AppLoader/AppLoader";

interface FilterProps {
    id: string;
    display_name: string;
    sort_by: string;
    type: string
}

const UserVendorsPage: FC = () => {
    const filterInitial: FilterProps = {
        id: '',
        display_name: '',
        sort_by: 'ASC',
        type: ''
    };

    const tableHeaders = [
        'Vendor ID',
        'Display name',
        'Type',
        'Email',
        'Contact',
        'Company',
        'Street address',
        'Apartment / Floor',
        'City',
        'State',
        'Country',
        'Phone',
        'Postal code',
        'Tags',
        'Actions'
    ];

    const smartInputs = [
        {
            name: 'id',
            placeholder: 'Search by ID',
            requestFunction: userVendorSearchById,
            valuesKey: 'id'
        },
        {
            name: 'display_name',
            placeholder: 'Search by display name',
            requestFunction: userVendorSearchByDisplayName,
            valuesKey: 'display_name'
        }
    ];

    const [filterValues, setFilterValues] = useState<FilterProps>(filterInitial);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [purchasesModalOpen, setPurchasesModalOpen] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState<UserVendor | undefined>(undefined);
    const [tags, setTags] = useState<string[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>(selectedVendor?.vendor_tags || []);
    const [isEditMode, setIsEditMode] = useState(false);

    const pagination = usePagination<UserVendor>(userVendorsPaginateRequest);

    useEffect(() => {
        pagination.paginate();
    }, []);

    const handleVendorModal = async () => {
        setIsModalOpen(!isModalOpen);
        setIsEditMode(false);

        await pagination.paginate();

        getAllPossibleTags().then((res) => {
            if (res instanceof HttpSuccessResponse) {
                setTags(res.data);
            }
        });
    };

    const handlePurchasesModal = (vendor: UserVendor) => {
        setSelectedVendor(vendor);
        setPurchasesModalOpen(true);
    }

    const handleClosePurchasesModal = () => {
        setSelectedVendor(undefined);

        setPurchasesModalOpen(false)
    }

    const handleEditVendor = async (vendor: UserVendor) => {
        setSelectedVendor(vendor);
        setIsModalOpen(!isModalOpen);
        setIsEditMode(true);

        getUserVendorTags(vendor?.id).then((res) => {
            if (res instanceof HttpSuccessResponse) {
                setTags(res.data.user_vendor_tags);
            }
        });
    };

    return (
        <Container className={'admin-users-page events'}>

            {!pagination.isLoading ?
                <div className={'w-100 mt-3'}>
                    <div style={{width: "fit-content"}} onClick={() => handleVendorModal()}>
                        <AppButton text={'Add vendor'}></AppButton>
                    </div>
                    <div className="mb-3 mt-3">
                        <Accordion>
                            <Accordion.Item eventKey={'0'}>
                                <Accordion.Header>
                                    Filters
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Formik
                                        initialValues={filterInitial}
                                        onSubmit={async (values) => {
                                            pagination.setPerPage(pagination.perPage);
                                            await pagination.paginate(values);
                                        }}
                                    >
                                        {({resetForm}) => {
                                            return (
                                                <Form className="table__filters"
                                                      onChange={(event) => handleOnFiltersChange(event, filterValues, setFilterValues)}>
                                                    <div className="table__filters__wrapper">
                                                        {smartInputs.map((input, index) => (
                                                            <AppSmartSearch
                                                                key={index + input.name}
                                                                name={input.name}
                                                                placeholder={input.placeholder}
                                                                requestFunction={input.requestFunction}
                                                                valuesKey={input.valuesKey}
                                                            />
                                                        ))}
                                                        <Field name={'type'} as="select">
                                                            <option value="">Type</option>
                                                            {Object.entries(VendorTypes).map((key, index) => (
                                                                <option key={String(index) + key}
                                                                        value={key[0]}>{key[1]}</option>
                                                            ))}
                                                        </Field>
                                                        <button type="reset"
                                                                className="filters-button-reset"
                                                                onClick={async () => {
                                                                    await handleReset(resetForm, filterInitial, pagination, setFilterValues);
                                                                }}
                                                        >
                                                            Reset
                                                        </button>
                                                        <button type="submit" className="filters-button-apply">
                                                            Apply Filters
                                                        </button>
                                                    </div>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <AppTable
                        columns={tableHeaders}
                        pagination={{
                            lastPage: pagination.lastPage,
                            currentPage: pagination.currentPage,
                            total_items: pagination.totalItems
                        }}
                        onPageChange={(page, perPage) => {
                            pagination.setCurrentPage(page);
                            pagination.setPerPage(perPage);
                            pagination.paginate();
                        }}>
                        <>
                            {pagination.items.length ? (
                                    pagination.items?.map((vendor, index) => (
                                        <tr data-row={index} key={vendor.id}>
                                            <td className={'clickable-td'}
                                                onClick={(() => handleEditVendor(vendor))}>{vendor.id}</td>
                                            <td>{vendor.display_name}</td>
                                            <td>{VendorTypes[vendor.type as keyof typeof VendorTypes]}</td>
                                            <td>{vendor.email}</td>
                                            <td>{vendor.first_name} {vendor.last_name}</td>
                                            <td>{vendor.company}</td>
                                            <td>{vendor.street_address}</td>
                                            <td>{vendor.apartment_floor}</td>
                                            <td>{vendor.city}</td>
                                            <td>{vendor.state}</td>
                                            <td>{vendor.country}</td>
                                            <td>{vendor.phone}</td>
                                            <td>{vendor.postal_code}</td>
                                            <td>{vendor.vendor_tags?.join(', ')}</td>
                                            <td>
                                                <div className={'customers_actions'}>
                                                    <div onClick={() => handleEditVendor(vendor)}>
                                                        <AppButton text={'Edit'}/>
                                                    </div>
                                                    <div onClick={(() => handlePurchasesModal(vendor))}>
                                                        <AppButton text={'Purchased inventories'}/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) :
                                <tr>
                                    <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>
                                        No results
                                    </td>
                                </tr>
                            }
                            <AppModal
                                headerClassName={'customers-modal-header'}
                                headerTitleClassName={'customers-modal-header__title'}
                                size={'lg'}
                                backdrop={'static'}
                                keyboard={false}
                                title={isEditMode ? 'Edit vendor' : 'Create new vendor'}
                                form={
                                    <CustomerOrVendorForm
                                        customerFormMode={false}
                                        onClose={() => handleVendorModal()}
                                        tags={tags}
                                        selectedTags={selectedTags}
                                        setSelectedTags={setSelectedTags}
                                        vendor={isEditMode ? selectedVendor : undefined}
                                        editMode={isEditMode}
                                    />
                                }
                                show={isModalOpen}
                                showHandle={handleVendorModal}
                                onClose={() => handleVendorModal()}
                            />
                            <AppModal
                                centered={true}
                                title={'Purchased inventories'}
                                customClass={'purchased-inventories-modal'}
                                form={
                                    <PurchasedInventories
                                        vendor={selectedVendor!}
                                    />
                                }
                                show={purchasesModalOpen}
                                showHandle={handleClosePurchasesModal}
                                onClose={() => {
                                }}
                            />
                        </>
                    </AppTable>
                </div>
                :
                <AppLoader/>
            }
        </Container>
    )
}

export default UserVendorsPage;
