import React, {ChangeEvent, FC, useEffect, useRef, useState} from 'react';
import {PasswordValidationSchema, SettingValidationSchema, SocialValidationSchema} from "./Data";
import {updatePasswordUserRequest, updateUserLogoRequest, updateUserRequest} from "../../../@api/User";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import {Field, Form, Formik, FormikProps, FormikValues} from "formik";
import DefaultFormikInput from "../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import renderPhoneInput from "../../../adapters/Formik/DefaultFormikPhoneInputAdapter";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import {GlobalStatusCodesEnum} from "../../../enums/ResponseStatuses/GlobalStatusCodesEnum";
import {AuthResponseStatusesEnum} from "../../../enums/ResponseStatuses/AuthResponseStatusesEnum";
import {HttpFailureResponse} from "../../../@api/Responses/HttpFailureResponse";
import {FormikErrors} from "formik/dist/types";
import "./SettingPage.scss"
import {Tab, Tabs} from "react-bootstrap";
import PaymentModal from "../SettingPage/components/PaymentModal/PaymentModal";
import {useSearchParams} from "react-router-dom";
import Logo from "../../../assets/images/user/logo.svg";
import BrokerPools from "./components/BrokerPools/BrokerPools";
import ApiKeys from "./components/ApiKeys/ApiKeys";
import {RolesEnum} from "../../../enums/RolesEnum";
import PoolsControl from "../../Admin/SettingsPage/PoolsControl/PoolsControl";
import {heightTableStyle} from '../../../helpers/SpecificStyles';

const SettingPage: FC = () => {
    const loggedUser = useSelector((state: RootState) => state.auth.user);
    const userBalance = useSelector((state: RootState) => state.auth.balance);
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token');

    const [closeDomain, setCloseDomain] = useState(loggedUser?.domain === null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAlertShown, setIsAlertShown] = useState(false);
    const [logo, setLogo] = useState<string>(Logo);
    const [showSuccessCopyMessage, setShowSuccessCopyMessage] = useState(false);

    const mainDomain = process.env.REACT_APP_ENV === 'production' ? 'myticketfeed.com' : 'staging.myticketfeed.com';

    /*
    let mainDomain = '';
    switch (process.env.REACT_APP_ENV) {
        case 'production':
            mainDomain = 'myticketfeed.com';
            break;
        case 'staging':
            mainDomain = 'myticketfeed.dev';
            break;
        case 'development':
            mainDomain = 'localhost:3000';
            break;
        default:
            mainDomain = 'myticketfeed.com';
            break;
    }
    */

    type GeneralFormValues = {
        email?: string;
        phone_number?: {
            phone_number?: string;
            alphabet_country_code?: string;
        };
        domain?: string | null;
    };
    const generalFormRef = useRef<FormikProps<GeneralFormValues>>(null);

    useEffect(() => {
        if (token && !isAlertShown) {
            setIsAlertShown(true);
            alert('Payment is processing, please wait for a moment...');
        }

        if (loggedUser) {
            if (loggedUser.logo) {
                setLogo(process.env.REACT_APP_CLOUDFRONT_BASE_URL + loggedUser.logo)
            }
        }
    }, [token, loggedUser, isAlertShown]);

    const toggleCloseDomain = (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        !closeDomain ? setFieldValue('domain', null) : setFieldValue('domain', '');
        setCloseDomain(!closeDomain);
    }

    const successResponse = (res: HttpSuccessResponse<GlobalStatusCodesEnum & AuthResponseStatusesEnum>) => {
        alert('Success update');
        window.location.reload();
    };

    const failureResponse = (
        res: HttpFailureResponse<GlobalStatusCodesEnum & AuthResponseStatusesEnum>,
        setErrors: (errors: FormikErrors<any>) => void,
    ) => {
        setErrors(res.validation ?? {});
        alert(res.message);
    };

    const passwordValues = [
        {key: "password_old", value: "Old Password"},
        {key: "password_first", value: "New Password"},
        {key: "password_second", value: "Confirm New Password"}
    ];

    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const copyDomain = () => {
        const domain = generalFormRef?.current?.values?.domain;
        const copyBox = document.createElement('textarea');
        copyBox.style.position = 'fixed';
        copyBox.style.left = '0';
        copyBox.style.top = '0';
        copyBox.style.opacity = '0';
        copyBox.value = `https://${domain}.${mainDomain}`;
        document.body.appendChild(copyBox);
        copyBox.focus();
        copyBox.select();
        document.execCommand('copy');
        document.body.removeChild(copyBox);
        setShowSuccessCopyMessage(true);
        setTimeout(() => {
            setShowSuccessCopyMessage(false);
        }, 2000);
    }

    const FormGeneral = () => {
        const handleSubmitGeneral = async (values: FormikValues, {setErrors}: {
            setErrors: (errors: FormikErrors<any>) => void
        }) => {
            const res = await updateUserRequest(loggedUser?.id || 0, values);
            return res instanceof HttpSuccessResponse ? successResponse(res) : failureResponse(res, setErrors);
        }

        return (
            <Formik
                innerRef={generalFormRef}
                initialValues={
                    {
                        email: loggedUser?.email,
                        phone_number: {
                            phone_number: loggedUser?.phone_number.phone_number,
                            alphabet_country_code: loggedUser?.phone_number.alphabet_country_code
                        },
                        phone_number_second: {
                            phone_number: loggedUser?.phone_number.phone_number_second,
                            alphabet_country_code: loggedUser?.phone_number.alphabet_country_code_second
                        },
                        domain: closeDomain ? null : loggedUser?.domain,
                    }
                }
                validationSchema={SettingValidationSchema}
                onSubmit={handleSubmitGeneral}
            >
                {({isSubmitting, setFieldValue}) =>
                    <Form className={'table-width'}>
                        <label htmlFor="email">Email</label>
                        <DefaultFormikInput name={'email'}
                                            placeholder={'Email'}
                                            autocomplete={'off'}
                                            id="email"
                                            class={'auth-wrapper__input'}
                        ></DefaultFormikInput>
                        <label htmlFor="phone_number">Phone</label>
                        <DefaultFormikInput name={'phone_number'}
                                            render={renderPhoneInput}
                                            autocomplete={'off'}
                                            class={'auth-wrapper__input'}
                                            id="phone_number"
                        ></DefaultFormikInput>
                        <label htmlFor="phone_number_second">Second phone</label>
                        <DefaultFormikInput name={'phone_number_second'}
                                            render={renderPhoneInput}
                                            autocomplete={'off'}
                                            class={'auth-wrapper__input'}
                                            id="phone_number_second"
                        ></DefaultFormikInput>
                        <label htmlFor="domain">My Ticket Feed domain</label>
                        <div className="domain">
                            <p className="domain__protocol">https://</p>
                            <DefaultFormikInput name={'domain'}
                                                id="domain"
                                                placeholder={'Domain Name'}
                                                autocomplete={'off'}
                                                class={'auth-wrapper__input subdomain-input'}
                                                disabled={closeDomain}
                            />
                            <p className="domain__main-domain">.{mainDomain}</p>
                            <button disabled={closeDomain} className="domain__copy-domain-button" type="button"
                                    onClick={copyDomain}>
                                Copy Link
                            </button>
                            <span className="copy-success" style={{display: showSuccessCopyMessage ? "block" : "none"}}>
                                Link copied to clipboard
                            </span>
                        </div>

                        <div className="setting__checkbox-wrapper">
                            <input
                                className="setting__checkbox"
                                type="checkbox"
                                checked={closeDomain}
                                onChange={() => toggleCloseDomain(setFieldValue)}
                                id="close-domain"/>
                            <label htmlFor="close-domain" className="setting__checkbox-label">
                                Disable My Ticket Feed page
                            </label>
                        </div>
                        {loggedUser!.role_id === RolesEnum.User &&
                            <div className="balance">
                                <div className="balance__info">
                                    <p className="balance__info-text">Balance</p>
                                    <p className="balance__info-text">$</p>
                                    <p className="balance__info-text">{userBalance}</p>
                                </div>
                                <div className="card-info" id="card-info"></div>

                                <button type="button" className="balance__button" onClick={() => openModal()}>
                                    Top Up Balance
                                </button>
                            </div>
                        }
                        <button className="auth-wrapper__submit-btn" type="submit"
                                disabled={isSubmitting}>Update
                        </button>
                    </Form>
                }
            </Formik>

        )
    };

    const FormPassword = () => {
        const handleSubmitPassword = async (values: FormikValues) => {
            const res = await updatePasswordUserRequest(loggedUser?.id || 0, values);
            alert(res?.message);
            window.location.reload();
        }
        return (
            <Formik
                initialValues={
                    {
                        password_old: '',
                        password_first: '',
                        password_second: ''
                    }
                }
                validationSchema={PasswordValidationSchema}
                onSubmit={handleSubmitPassword}
            >
                <Form className={'table-width'}>
                    {
                        passwordValues.map(passwordValue => (
                            <>
                                <label htmlFor="">{passwordValue.value}</label>
                                <DefaultFormikInput name={passwordValue.key}
                                                    type='password'
                                                    placeholder={passwordValue.value}
                                                    autocomplete={'off'}
                                                    class={'auth-wrapper__input'}
                                ></DefaultFormikInput>
                            </>
                        ))
                    }
                    <br></br>
                    <button className="auth-wrapper__submit-btn" type="submit">Update</button>
                </Form>
            </Formik>
        )
    };

    const FormSocial = () => {
        const [file, setFile] = useState<File>();

        const handleSubmitSocial = async (values: FormikValues) => {
            if (file) {
                const formData = new FormData();

                formData.append('file', file);

                await updateUserLogoRequest(loggedUser?.id || 0, formData).catch((e: Error) => {
                    alert(e);
                });
            }

            const res = await updateUserRequest(loggedUser?.id || 0, values);

            if (res.statusCode === 400) {
                alert(res?.message);
            } else {
                window.location.reload()
                alert(res?.message);
            }
        }

        const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.files) {
                setFile(e.target.files[0]);
            }
        };

        const colorOptions = ["white", "black", "blue", "red", "green"];

        return (
            <Formik
                initialValues={
                    {
                        email: loggedUser?.email,
                        logo: loggedUser?.logo,
                        tg_name: loggedUser?.tg_name,
                        visible_name: loggedUser?.visible_name,
                        name: loggedUser?.name,
                        company_name: loggedUser?.company_name,
                        color: loggedUser?.color,
                    }
                }
                validationSchema={SocialValidationSchema}
                onSubmit={handleSubmitSocial}
            >
                {({isSubmitting}) =>
                    <Form className="settings__social table-width">
                        <img className="settings__social__logo" src={logo} alt="logo"/>
                        <label htmlFor="">Change logo</label>
                        <input className="import-file__input" type='file' accept="image/*" onChange={handleFileChange}/>
                        <label htmlFor="">Your Telegram username: (without @)</label>
                        <DefaultFormikInput name={'tg_name'}
                                            placeholder={''}
                                            autocomplete={'off'}
                                            class={'auth-wrapper__input'}
                        ></DefaultFormikInput>
                        <label htmlFor="">Your Full name:</label>
                        <DefaultFormikInput name={'name'}
                                            placeholder={''}
                                            autocomplete={'off'}
                                            class={'auth-wrapper__input'}
                        ></DefaultFormikInput>
                        <div className="setting__checkbox-wrapper">
                            <Field type="checkbox" name="visible_name" id="visible_name" value="name"/>
                            <label htmlFor="visible_name" className="setting__checkbox-label">
                                Show on My Ticket Feed page
                            </label>
                        </div>
                        <label htmlFor="">Your Company name:</label>
                        <DefaultFormikInput name={'company_name'}
                                            placeholder={''}
                                            autocomplete={'off'}
                                            class={'auth-wrapper__input'}
                        ></DefaultFormikInput>
                        <div className="setting__checkbox-wrapper">
                            <Field type="checkbox" name="visible_name" id="company_name" value="company_name"/>
                            <label htmlFor="company_name" className="setting__checkbox-label">
                                Show on My Ticket Feed page
                            </label>
                        </div>
                        <div className="setting__select-wrapper events-page__filters__component">
                            <label className="select-label">Color Scheme:</label>
                            <Field name={'color'} as="select"
                                   style={{display: 'block', backgroundColor: 'white'}}>
                                {
                                    colorOptions.map(colorOption => (
                                        <option key={'color' + colorOption} value={colorOption}>{colorOption}</option>
                                    ))
                                }
                            </Field>
                        </div>

                        <button className="auth-wrapper__submit-btn" type="submit"
                                disabled={isSubmitting}>Update
                        </button>

                    </Form>
                }
            </Formik>
        )
    };

    const tabs = () => {
        if (loggedUser!.role_id === RolesEnum.User) {
            return (
                <Tabs defaultActiveKey={'general'}>
                    <Tab key="general" title={'General data'} eventKey={'general'}>
                        <FormGeneral/>
                    </Tab>
                    <Tab key="password" title={'Password'} eventKey={'password'}>
                        <FormPassword/>
                    </Tab>
                    <Tab key="social" disabled={closeDomain} title={'My Ticket Feed settings'} eventKey={'social'}>
                        <FormSocial/>
                    </Tab>
                    {
                        loggedUser!.pools.length ?
                            <Tab key="pools" title={'Broker pool settings'} eventKey={'pools'}>
                                <BrokerPools/>
                            </Tab>
                            :
                            null
                    }
                    <Tab key="keys" title={'API keys'} eventKey={'keys'}>
                        <ApiKeys/>
                    </Tab>
                </Tabs>)

        } else {
            return (
                <Tabs defaultActiveKey={'general'}>
                    <Tab key="general" title={'General data'} eventKey={'general'}>
                        <FormGeneral/>
                    </Tab>
                    <Tab key="password" title={'Password'} eventKey={'password'}>
                        <FormPassword/>
                    </Tab>
                    <Tab key="pools" title={'Pools control'} eventKey={'pools'}>
                        <PoolsControl/>
                    </Tab>
                    {/*<Tab key="notes" title={'Release notes'} eventKey={'notes'}>*/}
                    {/*    <ReleaseNotes/>*/}
                    {/*</Tab>*/}
                </Tabs>
            )
        }
    }

    return (
        <div className={'container settings'} style={heightTableStyle('settings')}>
            <h2>Update your data</h2>
            {tabs()}
            <PaymentModal
                key={'stripe-modal'}
                visible={isModalOpen}
                onClose={() => {
                    openModal();
                }}/>
        </div>
    );
};

export default SettingPage;
